/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { supabase } from "../../modules/auth/components/supabase";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, MultiValue } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import classes from "./custom.module.css";

interface Tags {
  label: string;
  value: string;
}

const organizationId = localStorage.getItem("organizationId");

const NewProject = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [is_active, setIsActive] = useState<boolean>(true);
  const [pin, setPin] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [tags, setTags] = useState<Tags[]>([]);
  const [projectAdded, setProjectAdded] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [validationError2, setValidationError2] = useState(false);
  const [projectCode, setProjectCode] = useState<string>("");
  const [btnLoader, setBtnLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getTags();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  let selectedProjectTags: any = [];
  const handleTagsChange = async (
    selectedTags: MultiValue<Tags>,
    actionMeta: ActionMeta<Tags>
  ) => {
    const newTag = selectedTags[selectedTags.length - 1];
    selectedProjectTags = selectedTags;
    if (newTag && !tags.find((tag) => tag.value === newTag.value)) {
      try {
        const { data, error } = await supabase
          .from("Tags")
          .insert({ name: newTag.value, organization_id: organizationId });
        if (error) {
          throw error;
        }
        const newTagObj = { label: newTag.value, value: newTag.value };
        setTags([...tags, newTagObj]);
      } catch (error) {
        console.error("Error adding tag:", error);
      }
    }
  };

  const addProject = async (e: any) => {
    e.preventDefault();
    if (name.length < 1) {
      setValidationError(true);
      setProjectAdded(false);
      return toast.error("Please enter name");
    }
    // if (description.length < 1) {
    //   setValidationError2(true);
    //   // @ts-ignore
    //   setProjectAdded(false);
    // }

    try {
      setProjectAdded(true);
      const hashedPin = pin;
      const tagValues = selectedProjectTags.map((tag: any) => tag.value);

      let fileData, fileExtension;
      fileExtension = file?.name.split(".").pop();
      if (!file) {
        // throw new Error("Please select a file");
        setProjectAdded(false);
      } else {
        setBtnLoader(true);
        const { data, error: newError } = await supabase.storage
          .from("project-logo")
          .upload(`project_${Date.now()}_${file.name}`, file, {
            cacheControl: "3600",
            upsert: true,
          });
        if (newError) {
          console.log("supabase error", newError);

          // @ts-ignore
          throw new Error(newError);
        }
        fileData = data;
      }
      const generateUrl = async (projectId: number) => {
        return `viewer/${projectId}`;
      };

      const { data: projectData, error: insertError } = await supabase
        .from("Projects")
        .insert({
          name,
          description,
          is_active,
          pin: hashedPin,
          logo: fileData?.path,
          tags: tagValues,
          organization_id: organizationId,
          project_code: projectCode,
        })
        .select();

      if (insertError) {
        throw new Error(insertError.message);
      }

      const url =
        projectData !== null && projectData.length > 0
          ? await generateUrl(+projectData[0].id)
          : null;

      const { error: updateError } = await supabase
        .from("Projects")
        .update({
          url,
        })
        // @ts-ignore
        .eq("id", projectData[0].id);

      console.log("supabase Error", updateError);

      console.log("in try Projects...");

      if (updateError) {
        // toast.error("supabase Error");
        throw new Error(updateError.message);
      } else {
        navigate("/projects");
        toast.success("Successfully added new project");
      }
      setBtnLoader(false);

      setName("");
      setDescription("");
      setProjectCode("");
      setIsActive(true);
      setPin("");
      setFile(null);
      setTags([]);
    } catch (error) {
      console.error("Error adding project:", error);
      // @ts-ignore
      toast.error(error.message);
      setBtnLoader(false);
    }
  };

  const getTags = async () => {
    try {
      const { data: tags, error } = await supabase.from("Tags").select("*");
      if (error) {
        throw error;
      }
      const mappedTags = tags.map((tag) => {
        return {
          label: tag.name,
          value: tag.name,
        };
      });
      setTags(mappedTags);
    } catch (error) {}
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={(e) => addProject(e)}>
        <div className="row">
          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">
              Project Name{" "}
              {validationError && (
                <span id="nameField" className="text-danger">
                  *
                </span>
              )}
            </label>
            <input
              type="text"
              className="form-control form-control-white"
              placeholder="Type Project Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              // required
            />
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Project Code</label>
            <input
              type="text"
              className="form-control form-control-white"
              placeholder=" Project code"
              value={projectCode}
              onChange={(e) => setProjectCode(e.target.value)}
              // required
            />
            {/* <div className="text-danger">
              {validationError && <span id="nameField">* Required field</span>}
            </div> */}
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Desciption</label>
            <textarea
              className="form-control form-control-white"
              placeholder="Project Desciption"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              // required
            />
            {/* <div className="text-danger">
              {validationError2 && <span id="nameField">* Required field</span>}
            </div> */}
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">PIN</label>
            <input
              type="text"
              className="form-control form-control-white"
              placeholder="Type Project Password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              // required
            />
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Tags</label>
            <CreatableSelect
              isMulti
              options={tags}
              onChange={handleTagsChange}
            />
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Logo</label>
            <input
              type="file"
              className="form-control form-control-dark"
              onChange={handleFileChange}
            />
          </div>
        </div>
        {btnLoader ? (
          <button
            type="submit"
            disabled={projectAdded}
            className="btn btn-primary"
          >
            <p className="d-flex">
              <div className={classes.btn_loader}></div>
              place wait...
            </p>
          </button>
        ) : (
          <button
            type="submit"
            // onClick={addProject}
            // disabled={projectAdded}
            className="btn btn-primary"
          >
            Add Project
          </button>
        )}
      </form>
    </>
  );
};

const NewProjectWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.PROJECTS_ADD" })}
      </PageTitle>
      <NewProject />
    </>
  );
};

export { NewProjectWrapper };
