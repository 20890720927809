import React from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import ProjectMasterLevels from "./ProjectMasterLevels";
import MasterManagementDetailView from "./MasterManagementDetailView";
import ProjectMasterFileType from "./ProjectMasterFileType";
import ProjectMasterVolume from "./ProjectMasterVolume";
import ProjectMasterDisciplines from "./ProjectMasterDisciplines";
import ProjectMasterQrCode from "./projectMasterQrCode";

const drawingBreadCrumbs: Array<PageLink> = [
  {
    title: "Profile",
    path: "/crafted/pages/profile/overview",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const MasterManagementPages = () => {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <Routes>
      <Route
        element={
          <>
            <MasterManagementDetailView />
            <Outlet />
          </>
        }
      >
        <Route
          path="levels"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>Levels</PageTitle>
              <ProjectMasterLevels />
            </>
          }
        />
        <Route
          path="file-type"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>File Type</PageTitle>
              <ProjectMasterFileType />
            </>
          }
        />
        <Route
          path="volume"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>Volume</PageTitle>
              <ProjectMasterVolume />
            </>
          }
        />
        <Route
          path="disciplines"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>
                Disciplines
              </PageTitle>
              <ProjectMasterDisciplines />
            </>
          }
        />
        <Route
          path="qrcode"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>
                Qr Code
              </PageTitle>
              <ProjectMasterQrCode />
            </>
          }
        />
        <Route
          index
          element={
            <Navigate
              to={`/project/detail/${projectId}/master-management/levels`}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default MasterManagementPages;
