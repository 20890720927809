import { Navigate, Outlet } from "react-router-dom";

function Protected() {
  const key = Object.keys(localStorage);
  const sbKey = key.find((k) => k.startsWith("sb") && k.endsWith("token"));

  const auth = localStorage.getItem(sbKey!);
  return auth ? <Outlet /> : <Navigate to="/auth" />;
}

export default Protected;
