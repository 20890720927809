import React from "react";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { supabase } from "../../modules/auth/components/supabase";
import { useNavigate, useParams } from "react-router-dom";
import { Card4 } from "../../../_metronic/partials/content/cards/Card4";
import style from "./custom.module.css";

const ProjectDetailViewDisciplines = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [projectDisciplines, setProjectDisciplines] = useState<any[]>([]);
  const [sortData, setSortData] = useState<any[]>([]);

  const fetchDisciplinesData = async () => {
    const { data, error } = await supabase
      .from("project_disciplines")
      .select("*,Drawings_v2_source(count)")
      .eq("project_id", projectId);

    if (error) {
      throw error;
    }

    setProjectDisciplines(data);
  };

  useEffect(() => {
    fetchDisciplinesData();
  }, []);

  useEffect(() => {
    if (sortData.length === 0) {
      const sortedData = [...projectDisciplines].sort(
        (a, b) => b.Drawings_v2_source[0].count - a.Drawings_v2_source[0].count
      );
      setSortData(sortedData);
    }
  }, [sortData]);

  const getDeliverableData = (id: any) => {
    // const detailurl = ;
    // const newurl = detailurl.replace("#", "");

    navigate(`/project/${projectId}/disciplines/${id}`);
  };

  return (
    <>
      {!sortData ? (
        <div className={style.loader_spinner}></div>
      ) : (
        <div>
          <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
            {sortData.map((discipline) => {
              const title = `${discipline.discipline_name} (${discipline.Drawings_v2_source[0].count})`;
              return (
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-3"
                  key={discipline.id}
                  onClick={() => getDeliverableData(discipline.id)}
                >
                  <Card4
                    icon="/media/svg/files/folder-document.svg"
                    title={title}
                    description=""
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetailViewDisciplines;
