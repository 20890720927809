/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { supabase } from "../../modules/auth/components/supabase";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { isValid, parseISO, parse, format } from "date-fns";
import classes from "./custom.module.css";

const organizationId = localStorage.getItem("organizationId");

const DrawingVersion = () => {
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [currentVersion, setCurrentVersion] = useState<number>();
  const [currentVersionData, setCurrentVersionData] = useState<any>();
  const [drawingVersionAdd, setDrawingVersionAdd] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  // const [validationError2, setValidationError2] = useState(false);
  const [date, setDate] = useState<any>("");
  const [newDate, setNewDate] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);

  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get("projectId");

  const { drawingId } = useParams();
  const navigate = useNavigate();
  const handleDateChange = async (selectedDate: any) => {
    setDate(selectedDate);
  };

  useEffect(() => {
    console.log("in effect date", date);
    if (date) {
      const formattedDate = format(date, "MM/dd/yyyy");
      console.log("formatted date", formattedDate);
      setNewDate(formattedDate);
    }
  }, [date]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    try {
      setFile(file!);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrentVersion = async () => {
    const { data, error }: any = await supabase
      .from("Drawing_Versions")
      .select("*")
      .eq("drawing_id", drawingId)
      .order("version_number", { ascending: false })
      .limit(1);

    if (data!.length > 0) {
      // @ts-ignore
      setCurrentVersion(data[0].version_number);
      setCurrentVersionData(data[0]);
    } else {
      setCurrentVersion(1);
    }
  };

  useEffect(() => {
    fetchCurrentVersion();
  }, [drawingId]);

  const addDrawingVersion = async (e: any) => {
    e.preventDefault();
    // if (description.length < 1) {
    //   setValidationError2(true);
    //   // navigate(`project/${drawingId}/drawing_version/edit`);
    // }
    setBtnLoader(true);
    try {
      setDrawingVersionAdd(true);
      let fileData, fileExtension;
      fileExtension = file?.name.split(".").pop();
      if (!file) {
        setDrawingVersionAdd(false);
        console.error("Please select a file");
        throw new Error("Please select a file");
      } else {
        const { data, error } = await supabase.storage
          .from("project-documents")
          .upload(`project_${Date.now()}_${file.name}`, file, {
            cacheControl: "3600",
            upsert: true,
          });

        if (error) {
          toast.error(error.message);
          throw error;
        }

        fileData = data;
      }

      const { data: drawingVersionData, error: insertError } = await supabase
        .from("Drawing_Versions")
        .insert({
          description,
          //   @ts-ignore
          version_number: currentVersion + 1,
          drawing_id: drawingId,
          file: fileData?.path,
          organization_id: organizationId,
          drawing_date: newDate ? newDate : new Date(),
          template_id: currentVersionData.template_id,
        })
        .select();

      if (insertError) {
        throw new Error(insertError.message);
      }

      const { data: drawingData, error: drawingError } = await supabase
        .from("Drawings_v2_source")
        .update({
          // @ts-ignore
          description: drawingVersionData[0].description,
          current_version: drawingVersionData[0].id,
          file: fileData?.path,
          updated_at: drawingVersionData[0].created_at,
        })
        .eq("id", drawingId);

      if (drawingError) {
        throw new Error(drawingError.message);
      } else {
        navigate(`/project/${projectId}`);
        toast.success("Successfully added drawing version");
      }
      setBtnLoader(false);
    } catch (error) {
      console.error("Error adding drawing version:", error);
      // @ts-ignore
      toast.error(error.message);
      setBtnLoader(false);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={addDrawingVersion}>
        <div className="mb-10">
          <label className="form-label">Desciption</label>
          <textarea
            className="form-control form-control-white"
            placeholder="Drawing version description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {/* <div className="text-danger">
            {validationError2 && <span>* Required field</span>}
          </div> */}
        </div>
        <div className="mb-10 me-1 row">
          <label className="form-label">Date</label>
          <DatePicker
            className="form-control form-control-white col"
            // @ts-ignore
            selected={date ? date : new Date()}
            onChange={handleDateChange}
          />
        </div>

        <div className="mb-10">
          <label className="form-label">File</label>
          <input
            required
            type="file"
            className="form-control form-control-dark"
            onChange={handleFileChange}
          />
        </div>
        {btnLoader ? (
          <button
            type="submit"
            disabled={drawingVersionAdd}
            className="btn btn-primary"
          >
            <div className="d-flex">
              <p className={classes.btn_loader}></p>
              place wait...
            </div>
          </button>
        ) : (
          <button
            type="submit"
            // onClick={addProject}
            // disabled={projectAdded}
            className="btn btn-primary"
          >
            Add Drawing version
          </button>
        )}
        {/* <button
          type="submit"
          // onClick={addDrawingVersion}
          className="btn btn-primary"
          disabled={drawingVersionAdd}
        >
          Add Drawing version
        </button> */}
      </form>
    </>
  );
};

const DrawingVersionWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DRAWING_VERSION" })}
      </PageTitle>
      <DrawingVersion />
    </>
  );
};

export { DrawingVersionWrapper };
