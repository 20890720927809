import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { KTIcon } from "../../../_metronic/helpers";
import { supabase } from "../../modules/auth/components/supabase";
import { toast } from "react-toastify";

const SetDrawingPin = ({ drawing }: any) => {
  const [show, setShow] = useState<boolean>(false);
  const [pin, setPin] = useState<any>(drawing?.pin);
  const [selectedDrawing, setSelectedDrawing] = useState<any>();
  const handleShow = () => setShow(true);

  useEffect(() => {

    const fetchData = async () => {
      const { data, error } = await supabase
        .from("Drawings_v2_source")
        .select("*")
        .eq("id", drawing.id)
        .single();

      if (data) setSelectedDrawing(data);
    };
    fetchData();
  }, []);

  const handleSubmitData = async (e: any) => {
    e.preventDefault();
    const { data, error } = await supabase
      .from("Drawings_v2_source")
      .update({ pin: pin })
      .eq("id", drawing.id)
      .select()
      .single();
    if (data) {
      console.log("DATA", data);

      setSelectedDrawing(data);
      toast.success("successfully update");
      setShow(false);
    } else {
      toast.error(error.message);
    }
  };
  //   console.log("PIN", drawing?.pin);

  return (
    <>
      <div
        className={`btn btn-icon btn-sm me-1 ${
          selectedDrawing?.pin ? "btn-light-primary" : "btn-secondary"
        }`}
        onClick={handleShow}
      >
        <KTIcon iconName="lock" className="fs-3" />
      </div>

      <Modal show={show} className={`modal-md `}>
        <Modal.Header className="bg-light-primary">
          <Modal.Title>Set Drawing New Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row" onSubmit={handleSubmitData}>
            <div className="col-12 d-flex align-items-center mb-5">
              <label className=" col-2 me-2 fw-bold text-gray-700">Pin</label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control form-control-sm form-control-solid  "
                  placeholder="Enter Pin"
                  name="pin"
                  value={pin}
                  onChange={(e: any) => setPin(e.target.value)}
                />
              </div>
            </div>

            <Modal.Footer>
              <div className="mt-5 d-flex justify-content-end">
                <Button
                  variant="secondary"
                  size="sm"
                  className="me-3"
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>

                <Button type="submit" variant="primary" size="sm">
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SetDrawingPin;
