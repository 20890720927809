import React, { FC, useEffect, useRef, useState } from "react";
import { SearchComponent } from "../../../assets/ts/components";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { Project } from "../../../../app/pages/dashboard/DashboardWrapper";

interface Props {
  projects: Project[];
}

const Search = ({ projects }: Props) => {
  const element = useRef<HTMLDivElement | null>(null);
  const resultsElement = useRef<HTMLDivElement | null>(null);
  const emptyElement = useRef<HTMLDivElement | null>(null);
  const [query, setQuery] = useState("");

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const filteredProjects = projects.filter((project) => {
    const searchFields = [
      project.name,
      project.description,
      project.tags.join(" "),
    ];
    const regex = new RegExp(query, "i");
    return searchFields.some((field) => regex.test(field));
  });

  const processs = (search: SearchComponent) => {
    setTimeout(function () {
      const number = Math.floor(Math.random() * 6) + 1;

      if (number === 3) {
        resultsElement.current!.classList.add("d-none");
        emptyElement.current!.classList.remove("d-none");
      } else {
        resultsElement.current!.classList.remove("d-none");
        emptyElement.current!.classList.add("d-none");
      }

      search.complete();
    }, 1500);
  };

  const clear = (search: SearchComponent) => {
    resultsElement.current!.classList.add("d-none");
    emptyElement.current!.classList.add("d-none");
  };

  useEffect(() => {
    const searchObject = SearchComponent.createInsance("#kt_header_search");

    searchObject!.on("kt.search.process", processs);
    searchObject!.on("kt.search.clear", clear);
  }, []);

  return (
    <>
      <div
        id="kt_header_search"
        className="d-flex align-items-stretch"
        data-kt-search-keypress="true"
        data-kt-search-min-length="2"
        data-kt-search-enter="enter"
        data-kt-search-layout="menu"
        data-kt-menu-trigger="auto"
        data-kt-menu-overflow="false"
        data-kt-menu-permanent="true"
        data-kt-menu-placement="bottom-end"
        ref={element}
      >
        <div
          className="d-flex align-items-center"
          data-kt-search-element="toggle"
          id="kt_header_search_toggle"
        >
          <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px">
            <KTIcon iconName="magnifier" className="fs-1" />
          </div>
        </div>

        <div
          data-kt-search-element="content"
          className="menu menu-sub menu-sub-dropdown p-4 w-325px w-md-375px"
        >
          <form
            data-kt-search-element="form"
            className="w-100 position-relative mb-3"
            autoComplete="off"
          >
            <KTIcon
              iconName="magnifier"
              className="fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-0"
            />

            <input
              type="text"
              className="form-control form-control-flush ps-10"
              name="search"
              placeholder="Search..."
              data-kt-search-element="input"
              onChange={handleQueryChange}
            />

            <span
              className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1"
              data-kt-search-element="spinner"
            >
              <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
            </span>

            <span
              className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none"
              data-kt-search-element="clear"
            >
              <KTIcon iconName="cross" className="fs-2 text-lg-1 me-0" />
            </span>
          </form>

          <div
            ref={resultsElement}
            data-kt-search-element="results"
            className="d-none"
          >
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <h3
                className="fs-5 text-muted m-0 pb-5"
                data-kt-search-element="category-title"
              >
                Results
              </h3>
              <tbody>
                {filteredProjects.map((project: any) => (
                  <tr key={project.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <a
                            href={`/project/${project.id}/detail`}
                            className="text-dark fw-bold text-hover-primary fs-6"
                          >
                            {project.name}
                          </a>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">
                            {project.description}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            ref={emptyElement}
            data-kt-search-element="empty"
            className="text-center d-none"
          >
            <div className="pt-10 pb-10">
              <KTIcon iconName="search-list" className="fs-4x opacity-50" />
            </div>

            <div className="pb-15 fw-bold">
              <h3 className="text-gray-600 fs-5 mb-2">No result found</h3>
              <div className="text-muted fs-7">
                Please try again with a different query
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Search };
