import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import style from "./management.module.css";
import { supabase } from "../../../modules/auth/components/supabase";

const EditDiscipline = ({ id }: any) => {
  const [show, setShow] = useState(false);
  const [disciplineName, setDisciplineName] = useState("");
  const [loader, setLoader] = useState(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchDisciplineData = async () => {
      const { data: discipline, error } = await supabase
        .from("master_disciplines")
        .select("*")
        .eq("id", id);
      if (error) {
        // @ts-ignore
        throw new Error(error);
      }
      // @ts-ignore
      setDisciplineName(discipline[0]?.discipline_name || "");
    };
    fetchDisciplineData();
  }, []);

  const updateDiscipline = async (e: any) => {
    e.preventDefault();
    setLoader(true);
    try {
      const { error: updateError } = await supabase
        .from("master_disciplines")
        .update({
          discipline_name: disciplineName,
        })
        .eq("id", id);

      if (updateError) {
        // @ts-ignore
        throw new Error(updateError.message);
      }
      toast.success("Discipline edit successfuly");
      setLoader(false);
      setShow(false);
      window.location.reload();
    } catch (error) {
      setLoader(false);
      // @ts-ignore
      toast.error(error.message);
      console.log("Error", error);
    }
  };
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <div className="d-flex align-items-center justify-content-center">
        <button className="border border-none bg-light" onClick={handleShow}>
          <KTIcon iconName="pencil" className="fs-3" />
        </button>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Edit discipline details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={updateDiscipline}>
            <div className="mb-3">
              <label className="form-label">name</label>
              <input
                type="text"
                name="disciplineName"
                value={disciplineName}
                onChange={(e: any) => setDisciplineName(e.target.value)}
                className="form-control"
                required
              />
            </div>

            <div className="mt-5 d-flex justify-content-end">
              <Button
                variant="secondary"
                size="sm"
                className="me-3"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              {loader ? (
                <Button
                  className="d-flex"
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  <div className={style.btn_loader}></div>
                  wait...
                </Button>
              ) : (
                <Button type="submit" variant="primary" size="sm">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditDiscipline;
