import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { KTIcon } from "../../../../_metronic/helpers";
import { supabase } from "../../../modules/auth/components/supabase";
import style from "./masterManagement.module.css";

const AddMasterFileType = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState({
    name: "",
    code: "",
  });
  const [loader, setLoader] = useState(false);

  const handleInput = (e: any) => {
    setFileType({ ...fileType, [e.target.name]: e.target.value });
  };

  const handleShow = () => setShow(true);

  const uploadNewFileType = async (e: any) => {
    e.preventDefault();
    setLoader(true);

    try {
      const { data: existingFileType } = await supabase
        .from("project_file_types")
        .select("name")
        .eq("name", fileType.name)
        .eq("project_id", projectId);

      if (existingFileType && existingFileType.length > 0) {
        console.error("Error: File type already exists");
        setLoader(false);
        toast.error("File type already exists");
      } else {
        const { data: fileTypeData, error: addError } = await supabase
          .from("project_file_types")
          .insert({
            name: fileType.name,
            code: fileType.code,
            project_id: projectId,
          })
          .eq("project_id", projectId);

        if (addError) {
          // @ts-ignore
          throw new Error(addError);
        }

        console.log("DATA", fileTypeData);

        setFileType({
          name: "",
          code: "",
        });
        setLoader(false);
        setShow(false);
        toast.success("File-Type added successfully");
        window.location.reload();
      }
    } catch (error) {
      setShow(true);
      setLoader(false);
      // @ts-ignore
      toast.error(error.message);
      console.log("Error", error);
    }
  };

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <div className="d-flex align-items-center justify-content-center">
        <Button variant="primary" onClick={handleShow}>
          <KTIcon iconName="plus" className="fs-3" />
          New File-Type
        </Button>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add File-Type details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={uploadNewFileType}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                name="name"
                value={fileType.name}
                onChange={handleInput}
                className="form-control"
                placeholder="Enter file type name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Short code</label>
              <input
                type="text"
                name="code"
                value={fileType.code}
                onChange={handleInput}
                className="form-control"
                placeholder="Enter file type short code"
                required
              />
            </div>
            <div className="mt-5 d-flex justify-content-end">
              <Button
                variant="secondary"
                size="sm"
                className="me-3"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              {loader ? (
                <Button
                  className="d-flex"
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  <div className={style.btn_loader}></div>
                  Wait...
                </Button>
              ) : (
                <Button type="submit" variant="primary" size="sm">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMasterFileType;
