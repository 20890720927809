import { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { supabase } from "../../../modules/auth/components/supabase";
import style from "../custom.module.css";

const MasterManagementDetailView = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<any>({});
  const location = useLocation();

  useEffect(() => {
    const fetchProject = async () => {
      const { data: projectData, error: projectError } = await supabase
        .from("Projects")
        .select("*")
        .eq("id", projectId)
        .single();

      if (projectError) {
        throw projectError;
      }
      setProject(projectData);
    };

    fetchProject();
  }, []);

  console.log("PROJECT NAME", project.name);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={style.main_wrapper}>
        <h2>{project.name}</h2>
        <h4 className="mb-5">Master Management</h4>
        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/project/detail/${projectId}/master-management/levels` &&
                    "active")
                }
                to={`/project/detail/${projectId}/master-management/levels`}
              >
                Levels
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/project/detail/${projectId}/master-management/file-type` &&
                    "active")
                }
                to={`/project/detail/${projectId}/master-management/file-type`}
              >
                File Types
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/project/detail/${projectId}/master-management/volume` &&
                    "active")
                }
                to={`/project/detail/${projectId}/master-management/volume`}
              >
                Volumes
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/project/detail/${projectId}/master-management/disciplines` &&
                    "active")
                }
                to={`/project/detail/${projectId}/master-management/disciplines`}
              >
                Disciplines
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/project/detail/${projectId}/master-management/qrcode` &&
                    "active")
                }
                to={`/project/detail/${projectId}/master-management/qrcode`}
              >
                Qr Code
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MasterManagementDetailView;
