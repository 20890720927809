import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { KTIcon } from "../../../../_metronic/helpers";
import { supabase } from "../../../modules/auth/components/supabase";
import style from "./management.module.css";

const EditDeliverables = ({ deliverable_data }: any) => {
  const organization_id = localStorage.getItem("organizationId");
  const [show, setShow] = useState(false);
  const [deliverable, setDeliverable] = useState({
    deliverable_name: deliverable_data.deliverable_name
      ? deliverable_data.deliverable_name
      : "",
    code: deliverable_data.code ? deliverable_data.code : "",
  });
  const [loader, setLoader] = useState(false);
  const handleShow = () => setShow(true);

  const handleInput = (e: any) => {
    setDeliverable({ ...deliverable, [e.target.name]: e.target.value });
  };

  const updateDeliverables = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    try {
      console.log(deliverable_data);

      const { data: find_deliverable, error: error_find_deliverable } =
        await supabase
          .from("master_deliverables")
          .select("*")
          .eq("code", deliverable.code)
          .eq("organization_id", organization_id);

      if (find_deliverable?.length !== 0) {
        throw new Error(
          "Given short code is allReady exist. You can not enter same code twice."
        );
      }

      const { error: updateError } = await supabase
        .from("master_deliverables")
        .update({
          deliverable_name: deliverable.deliverable_name,
          code: deliverable.code,
        })
        .eq("deliverable_id", deliverable_data.deliverable_id);

      if (updateError) {
        throw new Error(updateError.message);
      }
      toast.success("deliverable edit successfully");
      setLoader(false);
      setShow(false);
      window.location.reload();
    } catch (error) {
      setLoader(false);
      // @ts-ignore
      toast.error(error.message);
      console.log("Error", error);
    }
  };

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <div className="d-flex align-items-center justify-content-center">
        <button className="border border-none bg-light" onClick={handleShow}>
          <KTIcon iconName="pencil" className="fs-3" />
        </button>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Edit deliverable details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={updateDeliverables}>
            <div className="mb-3">
              <label className="form-label">name</label>
              <input
                type="text"
                name="deliverable_name"
                value={deliverable.deliverable_name}
                onChange={handleInput}
                className="form-control"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Short code</label>
              <input
                type="text"
                name="code"
                value={deliverable.code}
                onChange={handleInput}
                className="form-control"
                required
              />
            </div>
            <div className="mt-5 d-flex justify-content-end">
              <Button
                variant="secondary"
                size="sm"
                className="me-3"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              {loader ? (
                <Button
                  className="d-flex"
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  <div className={style.btn_loader}></div>
                  wait...
                </Button>
              ) : (
                <Button type="submit" variant="primary" size="sm">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditDeliverables;
