 const user_schema = {
    deliverable: {
      prop: "deliverables",
      type: String,
      required: true,
    },
    description: {
      prop: "description",
      type: String,
      required: true,
    },
    discipline: {
      prop: "disciplines",
      type: String,
      required: true,
    },
    drawing_date: {
      prop: "drawing_date",
      type: Date,
      required: true,
    },
    file_name: {
      prop: "file_name",
      type: String,
      required: true,
    },
    file_type: {
      prop: "file_type",
      type: String,
      required: true,
    },
    is_active: {
      prop: "is_active",
      type: Boolean,
      required: true,
    },
    level: {
      prop: "level",
      type: String,
      required: true,
    },
    drawing_name: {
      prop: "name",
      type: String,
      required: true,
    },
    volume: {
      prop: "volume",
      type: String,
      required: true,
    },
    revision: {
      prop: "revision",
      type: String,
      required: true,
    },
    template_name: {
      prop: "template_name",
      type: String,
      required: false,
    },
    pin: {
      prop: "pin",
      type: String,
      required: false,
    },
  };
export  {user_schema}
