/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { supabase } from "../../modules/auth/components/supabase";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import JSZip from "jszip";
import { user_schema } from "./schema";
import { toast, ToastContainer } from "react-toastify";
const organizationId = localStorage.getItem("organizationId");

const ImportFile = () => {
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [zipFile, setZipFile] = useState<File | null>(null);
  const [errorsData, setErrorsData] = useState<any>();
  const [filesAdded, setFilesAdded] = useState(false);

  const navigate = useNavigate();
  const { projectId } = useParams();

  function check_xls_errors(errors: any, rows: any) {
    const allErrors: any = [];

    rows.forEach((row: any, rowIndex: any) => {
      if (
        row.file_type === "2D Drawing (PDF)" &&
        row.template_name === undefined
      ) {
        console.log("row", row.file_name.split(".").pop());

        const errorMessage = `Template name is required for row #${
          rowIndex + 2
        }`;
        allErrors.push(errorMessage);
      }
      if (
        row.file_type === "2D Drawing (PDF)" &&
        row.file_name.split(".").pop() !== "pdf"
      ) {
        const errorMessage = `Please write valid file_name for row #${
          rowIndex + 2
        }`;
        allErrors.push(errorMessage);
      }
    });

    // Iterate over the errors array and print error messages
    errors.forEach((error: any) => {
      const errorMessage = `Required column '${error.column}' in row #${error.row}`;
      allErrors.push(errorMessage);
    });

    return allErrors;
  }

  const handleExcelFileChange = async (event: any) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "xlsx") {
      setExcelFile(file);

      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheet_name_list = workbook.SheetNames;
        const xlData = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheet_name_list[0]]
        );
        const excelRowCount = xlData.length;
        console.log(excelRowCount, "excelRowCount");
      };
      fileReader.readAsArrayBuffer(file);
      let schema = user_schema;

      const result = await readXlsxFile(file, { schema });
      console.log(result, "result");

      const hasErrors = check_xls_errors(result.errors, result.rows);
      setErrorsData(hasErrors);

      if (hasErrors) return;
    } else {
      console.log("Invalid Excel file:", fileName);
    }
  };

  const handleZipFileChange = async (event: any) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "zip") {
      setZipFile(file);
      // const zip = new JSZip();
      // await zip.loadAsync(file);
      // console.log(zip, "zip");

      // const zipFiles = zip.file(/./);
      // console.log(zipFiles.filter((name: any) => {console.log(name, "name"); return !name.includes('__MACOSX')}), "zipFiles");


      // const zipFileNames:any = Object.keys(zipFiles);
      // const zipFileCount = zipFileNames.filter((name: any) => {console.log(name, "name");
      //  return !name.startsWith('__MACOSX')}).length;
      // console.log(zipFileCount, "zipFileCount");
    } else {
      console.log("Invalid zip file:", fileName);
    }
  };

  const handleImportFiles = async () => {
    setFilesAdded(true);
    const excelFileNamedata = excelFile?.name;
    
    if (excelFileNamedata?.split(".").pop() !== "xlsx") {
      setFilesAdded(false);
      return toast.error("Please select a valid excel file");
    }
    const zipFileNamedata = zipFile?.name;
    if (zipFileNamedata?.split(".").pop() !== "zip") {
      setFilesAdded(false);
      return toast.error("Please select a valid zip file");
    }
    if (errorsData && errorsData.length > 0) {
      setFilesAdded(false);
      return toast.error("Please fill all required fields in the excel file");
    }
    const {
      data: { user },
    } = await supabase.auth.getUser();
    // console.log(user);

    if (excelFile && zipFile) {
      const excelFileName = excelFile.name;
      const zipFileName = zipFile.name;
      const fileTime = Date.now();

      const { data: excelData, error: excelError } = await supabase.storage
        .from("project-documents")
        .upload(`import/data/${fileTime}_${excelFileName}`, excelFile, {
          cacheControl: "3600",
          upsert: true,
        });
      // console.log("excelData", excelData);

      if (excelError) {
        console.error("Error uploading excel file:", excelError);
        return;
      }

      const { data: zipData, error: zipError } = await supabase.storage
        .from("project-documents")
        .upload(`import/data/${fileTime}_${zipFileName}`, zipFile, {
          cacheControl: "3600",
          upsert: true,
        });
      // console.log("zipData", zipData);

      if (zipError) {
        console.error("Error uploading zip file:", zipError);
        return;
      }

      const { data: importData, error: importError } = await supabase
        .from("importLogs")
        .insert([
          {
            project_id: projectId,
            organization_id: organizationId,
            excel_file: `${fileTime}_${excelFileName}`,
            zip_file: `${fileTime}_${zipFileName}`,
            user_id: user!.id,
          },
        ])
        .select();
      // console.log("importData", importData);

      if (importError) {
        console.error("Error saving import data:", importError);
        return;
      }
      setExcelFile(null);
      setZipFile(null);

      navigate(`/project/${projectId}/drawings`);
    }
  };

  return (
    <>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <div className="mb-10">
          <label className="form-label">Excel File</label>
          <input
            type="file"
            className="form-control form-control-dark"
            onChange={handleExcelFileChange}
          />
        </div>
        <div className="mb-10">
          <label className="form-label">Zip File</label>
          <input
            type="file"
            className="form-control form-control-dark"
            onChange={handleZipFileChange}
          />
        </div>
      </div>

      <button
        className="btn btn-primary"
        onClick={handleImportFiles}
        disabled={filesAdded}
      >
        {filesAdded ? "Uploading..." : `Import Files`}
      </button>

      <div>
        {errorsData && errorsData.length > 0 &&  (
          <table className="table table-bordered mt-5"
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No.</th>
                <th style={{ border: "1px solid black" }}>Error Messages</th>
              </tr>
            </thead>
            <tbody>
              {errorsData &&
                errorsData.map((error: any, index: any) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid black" }}>{index + 1}.</td>
                    <td
                      style={{ border: "1px solid black" }}
                      className="text-danger"
                    >
                      {error}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

const ImportFileWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.IMPORT_FILES" })}
      </PageTitle>
      <ImportFile />
    </>
  );
};

export { ImportFileWrapper };
