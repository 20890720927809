import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { supabase } from "../../../modules/auth/components/supabase";
import { toast } from "react-toastify";
import style from "./masterManagement.module.css";
import { Button, Modal } from "react-bootstrap";
import { KTIcon } from "../../../../_metronic/helpers";
import AddMasterVolume from "./AddMasterVolume";
import EditMasterVolume from "./EditMasterVolume";

const ProjectMasterVolume = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [valumes, setVolumes] = useState<any[]>([]);
  const [loader, setLoader] = useState<any>(false);
  const [deleteVolumeId, setDeleteVolumeId] = useState(null);

  useEffect(() => {
    setLoader(true);
    const fetchLevelsData = async () => {
      const {
        data: valumes,
        error,
        count,
      } = await supabase
        .from("project_buildings")
        .select("*", { count: "exact" })
        .eq("project_id", projectId)
        .range(
          (currentPage - 1) * recordsPerPage,
          (currentPage - 1) * recordsPerPage + recordsPerPage - 1
        );
      if (error) {
        // @ts-ignore
        throw new Error(error);
      }
      setCount(count!);
      console.log("LEVEL DATA", valumes);

      setVolumes(valumes);
      setLoader(false);
    };

    fetchLevelsData();
  }, [projectId, currentPage, recordsPerPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(count / recordsPerPage); i++) {
    pageNumbers.push(i);
  }
  console.log("levell pages", pageNumbers);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(count / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDelete = async (volumeId: string) => {
    console.log("delete id", volumeId);

    const { error } = await supabase
      .from("project_buildings")
      .delete()
      .eq("id", volumeId);
    if (error) {
      console.error("Error deleting volume:", error);
      toast.error(error.message);
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      {loader && <div className={style.loader_spinner}></div>}
      <div className="card-body py-3 mt-5">
        <div className="table-responsive">
          <div
            className="d-flex justify-content-space-between border mb-5"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h4>Volumes List</h4>
            <AddMasterVolume />
          </div>
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead className="table-light">
              <tr className="fw-bold text-muted">
                {/* <th>Id</th> */}
                <th className="min-w-150px">Name</th>
                <th className="min-w-150px">Short Code</th>
                <th className="min-w-100px">Actions</th>
              </tr>
            </thead>

            <tbody>
              {valumes.map((volume: any) => {
                return (
                  <tr key={volume.id}>
                    {/* <td>{level.id}</td> */}
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary fs-6"
                          >
                            {volume.name}
                          </a>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">
                            {}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-column w-100 me-2">
                        {volume.code}
                      </div>
                    </td>
                    <td>
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <EditMasterVolume id={volume.id} />
                      </a>

                      <button
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        onClick={() => setDeleteVolumeId(volume.id)}
                      >
                        <KTIcon iconName="trash" className="fs-3" />
                      </button>
                      <Modal
                        show={deleteVolumeId === volume.id}
                        onHide={() => setDeleteVolumeId(null)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Delete volume</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to delete this volume?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setDeleteVolumeId(null)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              handleDelete(volume.id);
                              setDeleteVolumeId(null);
                            }}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <ul className="pagination">
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <a href="#" className="page-link" onClick={previousPage}>
                  <i className="previous"></i>
                </a>
              </li>
              {pageNumbers.map((number, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                >
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Link>
                </li>
              ))}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(count / recordsPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <a href="#" className="page-link" onClick={nextPage}>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectMasterVolume;
