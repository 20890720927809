/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to="/projects"
        icon="element-11"
        title="PROJECTS"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/master-management/:userId"
        icon="element-11"
        title="MASTER MANAGEMENT"
        fontIcon="bi bi-buildings"
      />
    </>
  );
};

export { SidebarMenuMain };
