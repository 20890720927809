/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { supabase } from "../../modules/auth/components/supabase";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { isValid, parseISO, parse, format } from "date-fns";
import classes from "./custom.module.css";

const ProjectDrawingEdit = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [is_active, setIsActive] = useState(true);
  const [file, setFile] = useState(null);
  // const [config, setConfig] = useState("");
  const [loading, setLoading] = useState(false);
  const [drawingUpdate, setDrawingUpdate] = useState(false);
  const [levels, setLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState<any[]>([]);
  const [projectFileType, setProjectFileType] = useState<any[]>([]);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedFileTypeObject, setSelectedFileTypeObject] = useState<any>({});
  const [projectDisciplines, setProjectDisciplines] = useState<any[]>([]);
  const [selectedDisciplines, setSelectedDisciplines] = useState("");
  const [selectedDisciplinesObj, setSelectedDisciplinesObj] = useState<any>({});
  const [projectDeliverables, setProjectDeliverables] = useState<any[]>([]);
  const [selectedDeliverables, setselectedDeliverables] = useState("");
  const [selectedDeliverablesObj, setselectedDeliverablesObj] = useState<any>(
    {}
  );
  const [projectBuildings, setProjectBuildings] = useState<any[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState("");
  const [selectedBuildingsObj, setselectedBuildingsObj] = useState<any>({});
  const [date, setDate] = useState<any>("");
  const [newDate, setNewDate] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get("projectId");

  const { drawingId } = useParams();
  const navigate = useNavigate();

  const handleDateChange = async (selectedDate: any) => {
    setDate(selectedDate);
  };

  useEffect(() => {
    console.log("in effect date", date);
    if (date) {
      const formattedDate = format(date, "MM/dd/yyyy");
      console.log("formatted date", formattedDate);
      setNewDate(formattedDate);
    }
  }, [date]);

  const handleFileTypeChange = (selectedOptions: any) => {
    // setProjectFileType(selectedOptions);
    setSelectedFileType(selectedOptions.value);
    setSelectedFileTypeObject(selectedOptions);
  };

  const handleDisciplinesChange = (selectedOptions: any) => {
    console.log("value =>", selectedOptions);
    setSelectedDisciplines(selectedOptions.value);
    setSelectedDisciplinesObj(selectedOptions);
    setProjectDeliverables(selectedOptions.deliverables);
  };
  const handleDeliverablesChange = (selectedOptions: any) => {
    setselectedDeliverables(selectedOptions.value);
    setselectedDeliverablesObj(selectedOptions);
  };

  const handleBuilldingsChange = (selectedOptions: any) => {
    setSelectedBuildings(selectedOptions.value);
    setselectedBuildingsObj(selectedOptions);
  };

  const fetchLevels = async () => {
    const { data: levels, error } = await supabase
      .from("project_levels")
      .select("*")
      .eq("project_id", projectId);

    const { data: supabaseLevels, error: selectedLevelError } = await supabase
      .from("drawing_project_levels_v2_source")
      .select("*")
      .eq("drawing_id", drawingId);
    console.log("SELECTED DATA", supabaseLevels);

    let selectedLevelIds: any[] = [];
    supabaseLevels?.forEach((selectedLevel) => {
      selectedLevelIds.push(selectedLevel.level_id);
    });

    if (error) {
      throw error;
    }
    let finalLevels: any[] = [];
    const newSetLevels = levels.map((level) => {
      if (selectedLevelIds.indexOf(level.id) !== -1) {
        console.log("Selected ID  ==== ", level.id);
        finalLevels.push({
          label: level.name,
          value: level.id,
        });
      }
      return {
        label: level.name,
        value: level.id,
      };
    });
    setSelectedLevels(finalLevels);
    // @ts-ignore
    setLevels(newSetLevels);
  };

  useEffect(() => {
    async function fetchProjectDrawings() {
      try {
        setLoading(true);
        const { data: drawing, error } = await supabase
          .from("Drawings_v2_source")
          .select("*")
          .eq("id", drawingId)
          .single();

        if (error) {
          throw error;
        }

        if (drawing) {
          setName(drawing.name);
          setDescription(drawing.description);
          setIsActive(drawing.is_active);
          await setSelectedFileType(drawing.file_type_id);
          await setSelectedDisciplines(drawing.descipline_id);
          await setselectedDeliverables(drawing.deliverable_id);
          await setSelectedBuildings(drawing.building_id);
          let parsedDateObj = new Date();
          if (drawing.drawing_date != null) {
            parsedDateObj = parse(
              drawing.drawing_date,
              "yyyy-MM-dd",
              new Date()
            );
          }
          console.log(date);
          setDate(parsedDateObj);
          fetchLevels();
          fetchFileType(drawing.file_type_id);
          fetchDisciplinesData(drawing.descipline_id);
          fetchDeliverablesData(drawing.deliverable_id);
          fetchBuildingData(drawing.building_id);
          // setProjectDeliverables(selectedOptions.deliverables);
          // setConfig(drawing.config);
        }
        console.log("levels", selectedLevels);
        console.log("EDIT DRAWING", drawing);
      } catch (error) {
        console.error("Error fetching drawing:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchProjectDrawings();
  }, [drawingId]);

  const fetchFileType = async (fileTypeId: any) => {
    const { data: fileTypes, error } = await supabase
      .from("project_file_types")
      .select("*")
      .eq("project_id", projectId);
    if (error) {
      throw error;
    }
    console.log("SELECTED FILE TYPE", fileTypeId);
    const newSetLevels = fileTypes.map((file) => {
      if (file.id === fileTypeId) {
        setSelectedFileTypeObject({
          label: file.name,
          value: file.id,
        });
      }
      return {
        label: file.name,
        value: file.id,
      };
    });
    setProjectFileType(newSetLevels);
  };

  const fetchDisciplinesData = async (disciplineId: any) => {
    const { data: disciplines, error } = await supabase
      .from("project_disciplines")
      .select("*,project_deliverables(*)")
      .eq("project_id", projectId);
    if (error) {
      throw error;
    }
    const newDisciplinesData = disciplines.map((disciplines) => {
      let deliverablesArray: any = [];
      disciplines.project_deliverables.forEach((deliverable: any) => {
        deliverablesArray.push({
          label: deliverable.deliverable_name,
          value: deliverable.id,
        });
      });
      if (disciplines.id === disciplineId) {
        setSelectedDisciplinesObj({
          label: disciplines.discipline_name,
          value: disciplines.id,
        });
        setProjectDeliverables(deliverablesArray);
      }
      return {
        label: disciplines.discipline_name,
        value: disciplines.id,
        deliverables: deliverablesArray,
      };
    });
    // @ts-ignore
    setProjectDisciplines(newDisciplinesData);
  };

  const fetchDeliverablesData = async (deliverableId: any) => {
    const { data: deliverables, error } = await supabase
      .from("project_deliverables")
      .select("*")
      .eq("project_id", projectId);
    if (error) {
      throw error;
    }
    const newdeliverablesData = deliverables.map((deliverables) => {
      if (deliverables.id === deliverableId) {
        setselectedDeliverablesObj({
          label: deliverables.deliverable_name,
          value: deliverables.id,
        });
      }
      return {
        label: deliverables.deliverable_name,
        value: deliverables.id,
      };
    });
    // @ts-ignore
    setProjectDeliverables(newdeliverablesData);
  };

  const fetchBuildingData = async (deliverableId: any) => {
    const { data: buildings, error } = await supabase
      .from("project_buildings")
      .select("*")
      .eq("project_id", projectId);
    if (error) {
      throw error;
    }
    const newBulidingData = buildings.map((building) => {
      if (building.id === deliverableId) {
        setselectedBuildingsObj({
          label: building.name,
          value: building.id,
        });
      }
      return {
        label: building.name,
        value: building.id,
      };
    });
    // @ts-ignore
    setProjectBuildings(newBulidingData);
  };
  // useEffect(() => {
  //
  // }, []);

  // const handleFileChange = (event: any) => {
  //   setFile(event.target.files[0]);
  // };

  const handleLevelChange = (selectedOptions: any) => {
    setSelectedLevels(selectedOptions);
  };

  useEffect(() => {
    console.log("SELECTED DISCIPLINE ID UPDATED ", selectedDisciplines);
    projectDisciplines.forEach((discipline: any) => {
      if (discipline.id === selectedDisciplines) {
        setProjectDeliverables(discipline.deliverables);
      }
    });
  }, [selectedDisciplines]);
  console.log("deliverabes dataaa", projectDeliverables);

  const updateProjectDetails = async () => {
    setBtnLoader(true);
    try {
      console.log("SELECTED LEVELS", selectedLevels);
      setDrawingUpdate(true);
      let fileData, fileExtension;
      // @ts-ignore
      fileExtension = file?.name.split(".").pop();
      if (file) {
        const { data, error } = await supabase.storage
          .from("project-documents")
          // @ts-ignore
          .upload(`project_${Date.now()}.${fileExtension}`, file, {
            cacheControl: "3600",
            upsert: true,
          });

        if (error) {
          throw error;
        }

        fileData = data;
      }

      const { data, error } = await supabase
        .from("Drawings_v2_source")
        .update({
          name,
          description,
          is_active,
          file: fileData?.path,
          file_type_id: selectedFileType,
          descipline_id: selectedDisciplines,
          deliverable_id: selectedDeliverables,
          drawing_date: newDate,
          building_id: selectedBuildings,
          // config,
        })
        .eq("id", drawingId);
      console.log("PROJECT ID", drawingId);
      let levelData: any[] = [];
      selectedLevels.forEach((level) => {
        levelData.push({
          drawing_id: drawingId,
          level_id: level.value,
        });
      });
      console.log("LEVEL DATA", levelData);
      await supabase
        .from("drawing_project_levels_v2_source")
        .delete()
        .eq("drawing_id", drawingId);
      await supabase.from("drawing_project_levels_v2_source").insert(levelData);
      if (error) {
        throw error;
      } else {
        navigate(`/project/${projectId}`);
      }
      setBtnLoader(false);
      setName("");
      setDescription("");
      setIsActive(true);
      setFile(null);
      // setConfig("");
    } catch (error) {
      console.error("Error updating project:", error);
      setBtnLoader(false);
    }
  };

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="row">
            <div className="mb-10 col-sm-12 col-md-6">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control form-control-white"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-10 col-sm-12 col-md-6">
              <label className="form-label">Desciption</label>
              <textarea
                className="form-control form-control-white"
                placeholder="Type Desciption"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <label className="form-label">Levels</label>
              <Select
                isMulti
                options={levels}
                onChange={handleLevelChange}
                value={selectedLevels}
              />
            </div>

            <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <label className="form-label">File Type</label>
              <Select
                options={projectFileType}
                onChange={handleFileTypeChange}
                value={selectedFileTypeObject}
              />
            </div>

            <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <label className="form-label">Disciplines</label>
              <Select
                options={projectDisciplines}
                onChange={handleDisciplinesChange}
                value={selectedDisciplinesObj}
              />
            </div>

            <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <label className="form-label">Deliverable</label>
              <Select
                options={projectDeliverables}
                onChange={handleDeliverablesChange}
                value={selectedDeliverablesObj}
              />
            </div>

            <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <label className="form-label">Volume</label>
              <Select
                options={projectBuildings}
                onChange={handleBuilldingsChange}
                value={selectedBuildingsObj}
              />
            </div>

            <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <label className="form-label">Is Active?</label>
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  value="true"
                  id="trueRadio"
                  name="isActive"
                  checked={is_active}
                  onChange={(e) => setIsActive(e.target.value === "true")}
                />
                <label className="form-check-label" htmlFor="trueRadio">
                  TRUE
                </label>
              </div>

              <br />
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  value="false"
                  id="falseRadio"
                  name="isActive"
                  checked={!is_active}
                  onChange={(e) => setIsActive(e.target.value === "true")}
                />
                <label className="form-check-label" htmlFor="falseRadio">
                  FALSE
                </label>
              </div>

              {/* <div className="mb-10">
                <label className="form-label">Config</label>
                <input
                  type="text"
                  className="form-control form-control-white"
                  placeholder="Config"
                  value={config}
                  onChange={(e) => setConfig(e.target.value)}
                />
              </div> */}
            </div>
            <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <div>
                <label className="form-label">Date</label>
              </div>
              <DatePicker
                className="form-control form-control-white col"
                // @ts-ignore
                selected={date ? date : new Date()}
                onChange={handleDateChange}
              />
            </div>
            {/* <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <label className="form-label">Add Files</label>
              <input
                type="file"
                className="form-control form-control-dark"
                onChange={handleFileChange}
              />
            </div> */}
          </div>
          {btnLoader ? (
            <button
              type="submit"
              // disabled={drawingAdded}
              className="btn btn-primary"
            >
              <p className="d-flex">
                <div className={classes.btn_loader}></div>
                Updating...
              </p>
            </button>
          ) : (
            <button
              onClick={updateProjectDetails}
              className="btn btn-primary"

              // disabled={drawingAdded}
            >
              Update Drawing
            </button>
          )}
        </>
      )}
    </>
  );
};

const ProjectDrawingEditWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.EDIT_DRAWING" })}
      </PageTitle>
      <ProjectDrawingEdit />
    </>
  );
};

export { ProjectDrawingEditWrapper };
