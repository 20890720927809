import React, { useEffect, useState } from "react";
import Konva from "konva";
import { supabase } from "../../../modules/auth/components/supabase";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import style from "./management.module.css";
import { toast } from "react-toastify";
interface Props {
  imageData: any;
  templateId: any;
  onSave: any
}
const MasterQrCodeTemplate = ({ imageData, templateId ,onSave}: Props) => {
  const [pdfData, setPdfData] = useState<any>();
  const [loader, setLoader] = useState(false);
  // const imageData: any = localStorage.getItem("responseData");
  // const templateId: any = localStorage.getItem("templateId");
  const userId: any = localStorage.getItem("organizationId");
  const navigate = useNavigate();
  let img1: any;
  let img2: any;
  useEffect(() => {
    const stage = new Konva.Stage({
      container: "container",
      width: window.innerWidth,
      height: window.innerHeight,
      scaleX: 0.3,
      scaleY: 0.3,
    });

    const layer1 = new Konva.Layer();
    const layer2 = new Konva.Layer();

    const imageObj1 = new window.Image();
    imageObj1.onload = function () {
      img1 = new Konva.Image({
        x: 0,
        y: 0,
        image: imageObj1,
      });

      layer1.add(img1);
      stage.add(layer1);
    };

    imageObj1.src = imageData;
    const imageObj2 = new window.Image();
    imageObj2.onload = function () {
      img2 = new Konva.Image({
        x: 800,
        y: 500,
        image: imageObj2,
        width: 83,
        height: 83,
        // position: { x: 713, y: 2039 },
        draggable: true,
      });

      const tr = new Konva.Transformer({
        node: img2,
        rotateEnabled: false,
        enabledAnchors: [
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
        ],
      });

      layer2.add(img2);
      layer2.add(tr);
      stage.add(layer2);

      img2.dragBoundFunc(function (pos: any) {
        let img1Width = img1.width();
        let img1Height = img1.height();
        let img2Width = img2.width() * img2.scaleX();
        let img2Height = img2.height() * img2.scaleY();

        let newX = Math.round(pos.x) < img1.x() ? img1.x() : Math.round(pos.x);
        newX =
          newX > img1.x() + img1Width - img2Width
            ? img1.x() + img1Width - img2Width
            : newX;

        let newY = Math.round(pos.y) < img1.y() ? img1.y() : Math.round(pos.y);
        newY =
          newY > img1.y() + img1Height - img2Height
            ? img1.y() + img1Height - img2Height
            : newY;

        return {
          x: newX,
          y: newY,
        };
      });

      let isTransformActive = false;

      img2.on("click", function () {
        if (!isTransformActive) {
          tr.nodes([img2]);
          img2.draggable(true);
          isTransformActive = true;
        }
        layer2.draw();
      });

      stage.on("click", function (e: any) {
        if (e.target !== img2 && e.target !== tr) {
          tr.nodes([]);
          img2.draggable(false);
          isTransformActive = false;
          layer2.draw();
        }
      });

      stage.on("wheel", (e: any) => {
        e.evt.preventDefault();
        var oldScale = stage.scaleX();

        var pointer: any = stage.getPointerPosition();

        var mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };

        var newScale = e.evt.deltaY > 0 ? oldScale * 1.04 : oldScale * 0.95;

        stage.scale({ x: newScale, y: newScale });

        var newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        stage.position(newPos);
        stage.batchDraw();
      });

      var isMouseDown = false;
      var lastPos: any = { x: 0, y: 0 };

      stage.on("mousedown", function (e: any) {
        if (isTransformActive) return;
        isMouseDown = true;
        lastPos = stage.getPointerPosition();
      });

      stage.on("mousemove", function (e: any) {
        if (!isMouseDown || isTransformActive) {
          return;
        }

        var pos: any = stage.getPointerPosition();
        var delta = {
          x: pos.x - lastPos.x,
          y: pos.y - lastPos.y,
        };

        var stagePos = stage.position();

        stage.position({
          x: stagePos.x + delta.x,
          y: stagePos.y + delta.y,
        });

        lastPos = pos;
        stage.batchDraw();
      });

      stage.on("mouseup", function (e: any) {
        const pdfImageWidth = img1.width();
        const pdfImageHeight = img1.height();
        const newWidth = img2.width() * img2.scaleX();
        const newHeight = img2.height() * img2.scaleY();
        const position = img2.position();
        const updateAction = {
          pdfwidth: pdfImageWidth,
          pdfheight: pdfImageHeight,
          qrcodewidth: Math.round(newWidth),
          qrcodeheight: Math.round(newHeight),
          x: Math.round(position.x),
          y: Math.round(position.y),
        };
        setPdfData(updateAction);
        if (isTransformActive) return;
        isMouseDown = false;
      });
    };
    imageObj2.src = require("./qrcode.png");

    return () => {
      stage.destroy();
    };
  }, []);

  const handleDownload = async () => {
    setLoader(true);
    const { data, error } = await supabase
      .from("master_qrcode_template")
      .update({ action: pdfData })
      .eq("id", templateId)
      .select();

    if (!error) {
      setLoader(false);
      onSave(false);
      toast.success("Data updated successfully");
    } else {
      console.error("Error updating data:", error);
      // Handle error if needed
    }
  };

  return (
    <>
      <div className="text-end m-1">
        {loader ? (
          <Button  type="submit" className="d-flex float-end mb-2" variant="primary" size="sm">
            <div className={style.btn_loader}></div>
            Wait...
          </Button>
        ) : (
          <Button  onClick={handleDownload} variant="primary" size="sm">
            Save
          </Button>
        )}
      </div>
      <div
        id="stagescroll"
        style={{ width: "100%", height: "100%", overflow: "scroll" }}
      >
        <div id="container"></div>
      </div>
    </>
  );
};

export default MasterQrCodeTemplate;
