import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { KTIcon } from "../../../../_metronic/helpers";
import { supabase } from "../../../modules/auth/components/supabase";
import style from "./masterManagement.module.css";

const EditMasterVolume = ({ id }: any) => {
  const [show, setShow] = useState(false);
  const [volume, setVolume] = useState({
    name: "",
    code: "",
  });
  const [loader, setLoader] = useState(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchLevels = async () => {
      const { data: volumeData, error } = await supabase
        .from("project_buildings")
        .select("*")
        .eq("id", id);
      if (error) {
        // @ts-ignore
        throw new Error(error);
      }
      // @ts-ignore
      setVolume(volumeData[0]);
    };
    fetchLevels();
  }, []);

  const handleInput = (e: any) => {
    setVolume((prevVolume) => ({
      ...prevVolume,
      [e.target.name]: e.target.value,
    }));
  };

  const updateVolume = async (e: any) => {
    e.preventDefault();
    setLoader(true);
    try {
      const { error: updateError } = await supabase
        .from("project_buildings")
        .update({
          name: volume.name,
          code: volume.code,
        })
        .eq("id", id);

      if (updateError) {
        // @ts-ignore
        throw new Error(updateError.message);
      }
      toast.success("Volume edit successfuly");
      setLoader(false);
      setShow(false);
      window.location.reload();
    } catch (error) {
      setLoader(false);
      // @ts-ignore
      toast.error(error.message);
      console.log("Error", error);
    }
  };

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <div className="d-flex align-items-center justify-content-center">
        <button className="border border-none bg-light" onClick={handleShow}>
          <KTIcon iconName="pencil" className="fs-3" />
        </button>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Edit volume details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={updateVolume}>
            <div className="mb-3">
              <label className="form-label">name</label>
              <input
                type="text"
                name="name"
                value={volume.name}
                onChange={handleInput}
                className="form-control"
                // placeholder=""
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Short code</label>
              <input
                type="text"
                name="code"
                value={volume.code}
                onChange={handleInput}
                className="form-control"
                // placeholder="Enter version"
                required
              />
            </div>
            <div className="mt-5 d-flex justify-content-end">
              <Button
                variant="secondary"
                size="sm"
                className="me-3"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              {loader ? (
                <Button
                  className="d-flex"
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  <div className={style.btn_loader}></div>
                  wait...
                </Button>
              ) : (
                <Button type="submit" variant="primary" size="sm">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditMasterVolume;
