/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { supabase } from "../../modules/auth/components/supabase";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { KTIcon, KTSVG } from "../../../_metronic/helpers";
import { QRCode } from "react-qrcode-logo";
import * as React from "react";
import style from "./custom.module.css";

interface Drawing {
  id: string;
  name: string;
  url: string;
  description: string;
  file: string;
  is_active: boolean;
}

interface Project {
  id: string;
  name: string;
  description: string;
  logo: string;
  drawings: Drawing[];
  tags: string[];
  url: string;
}

const qrLogo = localStorage.getItem("qrLogo");

const ProjectDetail = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const location = useLocation();

  const [project, setProject] = useState<Project | null>(null);
  const [showCopied, setShowCopied] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [drawingTypes, setDrawingTypes] = useState([]);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string>("");
  const [drawingVersion, setDrawingVersion] = useState([]);
  const [versionHistory, setVersionHistory] = useState([]);
  const [count, setCount] = useState<number>(0);
  const [deleteDrawingId, setDeleteDrawingId] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(15);
  const [pageProduct, setPageProduct] = useState([]);
  const [loading, setLoading] = useState(true);

  const projectQrUrl = "http://localhost:3012/viewer/projects";

  const setQrCode = (url: string) => {
    setQrCodeDataUrl(url);
  };

  const downloadQRCode = (url?: string, id?: string) => {
    console.log("url", url);
    console.log("id", id);

    if (!id) {
      console.error("Invalid ID provided");
      return;
    }

    setQrCodeDataUrl(url as string);
    const qrCodeCanvas = document.getElementById(id) as HTMLCanvasElement;

    if (!qrCodeCanvas) {
      console.error("Element not found");
      return;
    }

    const link = document.createElement("a");
    link.href = qrCodeCanvas.toDataURL(url);
    link.download = "qrcode.png";
    link.click();
    console.log("qr code", qrCodeCanvas);
  };

  useEffect(() => {
    const fetchDrawingTypes = async () => {
      // console.log("data  show");

      try {
        const { data, error } = await supabase
          .from("Drawing_Types")
          .select("*");

        if (error) {
          throw error;
        }

        // @ts-ignore
        setDrawingTypes(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDrawingTypes();


  }, []);

  const fetchSignedUrl = (file: string): string => {
    if (!file) {
      return "";
    }
    try {
      const { data } = supabase.storage.from("project-logo").getPublicUrl(file);
      return data!.publicUrl;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const { data: projectData, error: projectError } = await supabase
          .from("Projects")
          .select("*")
          .eq("id", projectId)
          .single();

        if (projectError) {
          throw projectError;
        }

        const url = fetchSignedUrl(projectData!.logo);
        setLogoUrl(url);

        const {
          data: drawingsData,
          error: drawingsError,
          count,
        } = await supabase
          .from("Drawings_v2_source")
          .select(`*, Organization(*), Drawing_Versions(*)`, { count: "exact" })
          .eq("project_id", projectId)
          .order("id", { ascending: true })
          .range(
            (currentPage - 1) * recordsPerPage,
            (currentPage - 1) * recordsPerPage + recordsPerPage - 1
          );

        // console.log("data", drawingsData);
        // @ts-ignore
        setPageProduct(drawingsData);

        const drawingV = drawingsData?.map((d: any) => {
          return d.Drawing_Versions;
        });
        // @ts-ignore
        setDrawingVersion(drawingV);
        setCount(count!);
        // @ts-ignore
        // setQrCode();

        if (drawingsError) {
          console.log(drawingsError);
          throw drawingsError;
        }

        // @ts-ignore
        setProject((prevProject) => {
          if (!prevProject) {
            return { ...projectData, drawings: drawingsData };
          }

          return {
            ...prevProject,
            ...projectData,
            drawings: [...prevProject.drawings, ...drawingsData],
          };
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProject();
  }, [projectId, currentPage, recordsPerPage]);

  // useEffect(() => {
  //   let qrCode = document.querySelector("#myQRCodeRow");
  //   console.log("qr id ", qrCode);
  //   downloadQRCode();
  // }, []);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(count / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(count / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // @ts-ignore

  const getImageSrc = (project: any) => {
    // @ts-ignore
    const url = logoUrl[project.logo];
    if (logoUrl) {
      return logoUrl;
    }
    const projectName = project.name.trim();
    const firstLetter = projectName[0].toUpperCase();
    return `https://dummyimage.com/200x200/000/eee&text=${firstLetter}`;
  };

  const handleDelete = async (drawingId: string) => {
    const { error } = await supabase
      .from("Drawings_v2_source")
      .delete()
      .eq("id", drawingId);
    if (error) {
      console.error("Error deleting drawing:", error);
    } else {
      window.location.reload();
    }
  };

  const handleCopyLink = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const link = event.currentTarget.href;
    try {
      await navigator.clipboard.writeText(link);
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  const getVersionHistory = (drawingId: number) => {
    const data = drawingVersion.filter(
      (version: any) => version.drawing_id === drawingId
    );
    setVersionHistory(data);
  };

  if (!project) {
    return <div className={style.loader_spinner}></div>;
  }

  return (
    <>
      <div className={`card mb-xl-10 border ${style.main_wrapper}`}>
        <div className="card-body pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  src={getImageSrc(project)}
                  alt={project.name}
                  style={{ width: "80px", height: "80px" }}
                />
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <div className="align-items-center mb-2">
                      <div className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                        {project.name}
                      </div>
                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        {project.tags &&
                          project.tags.map((tag: any) => (
                            <span
                              key={tag.id}
                              className="badge badge-light-danger fw-semibold me-1"
                            >
                              {tag}
                            </span>
                          ))}
                        <a
                          href={
                            project.id
                              ? `${projectQrUrl}/${project.id}`
                              : "www.google.com"
                          }
                          target="_blank"
                          onClick={handleCopyLink}
                          title={showCopied ? "Link copied to clipboard!" : ""}
                        >
                          <i className="fa-solid fa-link"></i>
                          {" Shareable Link"}
                        </a>
                      </div>
                    </div>

                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 ms-10 mb-3">
                      <div className="d-flex align-items-center">
                        <KTIcon iconName="file" className="fs-3 me-2" />
                        <div className="fs-2 fw-bolder">{count}</div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        {project.drawings.length === 1 ? "Drawing" : "Drawings"}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <span>
                      <a
                        href={`/project/detail/${project.id}/import`}
                        className="btn btn-light-primary mb-3"
                      >
                        Import
                      </a>
                    </span>
                  </div>
                  <div>
                    <span>
                      <NavLink
                        to={`/project/detail/${project.id}/master-management/levels`}
                        className="btn btn-light-primary"
                      >
                        Master-Management
                      </NavLink>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex overflow-auto h-55px ms-10">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/project/${projectId}/drawings` &&
                    "active")
                }
                to={`/project/${projectId}/drawings`}
              >
                Drawings
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/project/${projectId}/disciplines` &&
                    "active")
                }
                to={`/project/${projectId}/disciplines`}
              >
                Disciplines
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const ProjectDetailWrapper: FC = () => {
  return (
    <>
      <ProjectDetail />
    </>
  );
};

export { ProjectDetailWrapper };
