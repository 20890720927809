import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage } from "../modules/auth";
import { App } from "../App";
import Protected from "../../utils/Helper/Protected";
import MasterQrCodeTemplate from "../pages/projects/MaserManagement/MasterQrCodeTemplate";

const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="auth/*" element={<AuthPage />} />
          <Route element={<Protected />}>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route path="/" element={<Navigate to="/projects" />} />
            <Route
              path="/"
              element={<Navigate to="/master-management/:userId" />}
            />
            <Route path="/" element={<Navigate to="/project/add" />} />
            <Route
              path="/"
              element={<Navigate to="/project/:projectId/detail" />}
            />
            <Route
              path="/"
              element={<Navigate to="/project/detail/:projectId/import" />}
            />
            <Route
              path="/"
              element={
                <Navigate to="/project/detail/:projectId/master-management" />
              }
            />
            <Route
              path="/"
              element={<Navigate to="/project/:projectId/details/add" />}
            />
            <Route
              path="/"
              element={<Navigate to="/project/:drawingId/drawing/edit" />}
            />
            <Route
              path="/"
              element={
                <Navigate to="/project/:drawingId/drawing_version/edit" />
              }
            />
            <Route
              path="/"
              element={<Navigate to="/project/:projectId/edit" />}
            />
            <Route
              path="/"
              element={<Navigate to="/project/:projectId/drawings" />}
            />
            <Route
              path="/"
              element={<Navigate to="/project/:projectId/disciplines" />}
            />
            <Route
              path="/"
              element={
                <Navigate to="/project/:projectId/disciplines/:discipinesId" />
              }
            />
            <Route
              path="/"
              element={
                <Navigate to="/project/:projectId/disciplines/:discipinesId/:deliverId/deliverables" />
              }
            />
            <Route
              path="/"
              element={<Navigate to="/project/:projectId/drawings" />}
            />
            <Route
              path="/"
              element={
                <Navigate to="/project/detail/:projectId/master-management/levels" />
              }
            />
            <Route
              path="/"
              element={
                <Navigate to="/project/detail/:projectId/master-management/file-type" />
              }
            />
            <Route
              path="/"
              element={
                <Navigate to="/project/detail/:projectId/master-management/volume" />
              }
            />

            <Route
              path="/"
              element={
                <Navigate to="/project/detail/:projectId/master-management/disciplines" />
              }
            />
            <Route
              path="/"
              element={
                <Navigate to="/project/detail/:projectId/master-management/qrcode" />
              }
            />
            <Route
              path="/"
              element={<Navigate to="/master-management/:userId/levels" />}
            />
            <Route
              path="/"
              element={<Navigate to="/master-management/:userId/file-type" />}
            />
            <Route
              path="/"
              element={<Navigate to="/master-management/:userId/volume" />}
            />

            <Route
              path="/"
              element={<Navigate to="/master-management/:userId/disciplines" />}
            />
            <Route
              path="/"
              element={<Navigate to="/master-management/:userId/qrcode" />}
            />
            {/* <Route
              path="/master-management/:userId/qrcode/template"
              element={<MasterQrCodeTemplate />}
            /> */}
            <Route
              path="/"
              element={
                <Navigate to="/project/detail/:projectId/master-management/levels/:levelId/edit" />
              }
            />
            <Route path="/logout" element={<Logout />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
