import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { KTIcon } from "../../../../_metronic/helpers";
import { toast } from "react-toastify";
import { supabase } from "../../../modules/auth/components/supabase";
import style from "./masterManagement.module.css";
import AddMasterNewQrCodePdf from "./AddMasterQrCodePdf";
import moment from "moment";

function ProjectMasterQrCode() {
  const { projectId } = useParams<{ projectId: string }>();
  // const userId = localStorage.getItem("organizationId");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [templates, settemplates] = useState<any[]>([]);
  const [loader, setLoader] = useState<any>(false);
  const [deletetemplateId, setDeletetemplateId] = useState(null);

  useEffect(() => {
    setLoader(true);
    const fetchLevelsData = async () => {
      const {
        data: templates,
        error,
        count,
      } = await supabase
        .from("project_qrcode_template")
        .select("*", { count: "exact" })
        .eq("project_id", projectId)
        .range(
          (currentPage - 1) * recordsPerPage,
          (currentPage - 1) * recordsPerPage + recordsPerPage - 1
        );
      if (error) {
        // @ts-ignore
        throw new Error(error);
      }
      setCount(count!);
      console.log("LEVEL DATA", templates);

      settemplates(templates);
      setLoader(false);
    };

    fetchLevelsData();
  }, [projectId, currentPage, recordsPerPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(count / recordsPerPage); i++) {
    pageNumbers.push(i);
  }
  console.log("levell pages", pageNumbers);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(count / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDelete = async (templateId: string, templateFile: any) => {
    const { data: deleted, error: deleteError }: any = await supabase
      .from("project_qrcode_template")
      .select()
      .eq("id", templateId);
    const { data, error }: any = await supabase
      .from("project_qrcode_template")
      .delete()
      .eq("id", templateId);

    //==========================> if file delete from the storage <============================
    if (deleted[0].master_id === null) {
      const { error: storageError } = await supabase.storage
        .from("project-documents")
        .remove([templateFile]);
      if (storageError) {
        console.error(
          "Error deleting file from storage:",
          storageError.message
        );
        toast.error(storageError.message);
        return;
      }
    }
    if (error) {
      console.error("Error deleting template:", error);
      toast.error(error.message);
    } else {
      window.location.reload();
    }
  };
  const handleImportFromMaster = async () => {
    const { data, error }: any = await supabase
      .from("master_qrcode_template")
      .select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
      return;
    }

    const formattedData = await Promise.all(
      data.map(async (row: any) => {
        // Check if data already exists
        const { data: existingData } = await supabase
          .from("project_qrcode_template")
          .select("*")
          .eq("master_id", row.id)
          .eq("project_id", projectId);

        // If data does not exist, return the row for insertion
        if (!existingData || existingData.length === 0) {
          return {
            project_id: projectId,
            name: row.name,
            file: row.file,
            action: row.action,
            organization_id: row.organization_id,
            master_id: row.id,
          };
        }
      })
    );

    // Filter out undefined values (i.e., rows that already exist)
    const dataToInsert = formattedData.filter((row) => row !== undefined);

    const { data: insertData, error: insertError } = await supabase
      .from("project_qrcode_template")
      .insert(dataToInsert)
      .select();

    if (insertError) {
      console.error("Error inserting data:", insertError.message);
      return;
    } else {
      toast.success("Data imported successfully");
      window.location.reload();
    }
  };

  function getTextAfterSecondUnderscore(fileName: any) {
    const parts = fileName.split("_");
    if (parts.length > 2) {
      return parts.slice(2).join("_"); // Combine remaining parts after the second underscore
    } else {
      return ""; // Return empty string if there are less than 2 underscores
    }
  }

  return (
    <>
      <div>
        <Button onClick={handleImportFromMaster}>
          <KTIcon iconName="plus" className="fs-3" />
          Import From Master
        </Button>
      </div>

      {loader && <div className={style.loader_spinner}></div>}
      <div className="card-body py-3 mt-5">
        <div className="table-responsive">
          <div
            className="d-flex justify-content-space-between border mb-5"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h4>Qr Code Template List</h4>
            <AddMasterNewQrCodePdf />
          </div>
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead className="table-light">
              <tr className="fw-bold text-muted">
                {/* <th>Id</th> */}
                <th className="min-w-150px">Name</th>
                <th className="min-w-150px">File Name</th>
                <th className="min-w-150px">Date</th>
                <th className="min-w-100px">Actions</th>
              </tr>
            </thead>

            <tbody>
              {templates.map((template: any) => {
                return (
                  <tr key={template.id}>
                    {/* <td>{level.id}</td> */}
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary fs-6"
                          >
                            {template.name}
                          </a>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">
                            {}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-column w-100 me-2">
                        {getTextAfterSecondUnderscore(template.file)}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-2">
                        {moment(template.created_at).format("DD-MM-YYYY")}
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        onClick={() => setDeletetemplateId(template.id)}
                      >
                        <KTIcon iconName="trash" className="fs-3" />
                      </button>
                      <Modal
                        show={deletetemplateId === template.id}
                        onHide={() => setDeletetemplateId(null)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Delete template</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to delete this tempale?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setDeletetemplateId(null)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              handleDelete(template.id, template.file);
                              setDeletetemplateId(null);
                            }}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <ul className="pagination">
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <a href="#" className="page-link" onClick={previousPage}>
                  <i className="previous"></i>
                </a>
              </li>
              {pageNumbers.map((number, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                >
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Link>
                </li>
              ))}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(count / recordsPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <a href="#" className="page-link" onClick={nextPage}>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectMasterQrCode;
