import { NavLink, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import style from "../custom.module.css";

const MasterManagementDetails = () => {
  //   const { projectId } = useParams<{ projectId: string }>();
  const location = useLocation();
  const userId = localStorage.getItem("organizationId");
  console.log("USER ID", userId);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={style.main_wrapper}>
        <h3 className="mb-5">Master Management</h3>
        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/master-management/${userId}/levels` && "active")
                }
                to={`/master-management/${userId}/levels`}
              >
                Levels
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/master-management/${userId}/file-type` && "active")
                }
                to={`/master-management/${userId}/file-type`}
              >
                File Types
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/master-management/${userId}/volume` && "active")
                }
                to={`/master-management/${userId}/volume`}
              >
                Volumes
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/master-management/${userId}/disciplines` && "active")
                }
                to={`/master-management/${userId}/disciplines`}
              >
                Disciplines
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    `/master-management/${userId}/qrcode` && "active")
                }
                to={`/master-management/${userId}/qrcode`}
              >
                Qr Code
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MasterManagementDetails;
