import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import { NewProjectWrapper } from "../pages/projects/AddNewProject";
import { ProjectDetailWrapper } from "../pages/projects/ProjectDetailView";
import { AddProjectDetailWrapper } from "../pages/projects/AddProjectDrawing";
import { ProjectDrawingEditWrapper } from "../pages/projects/EditProjectDrawing";
import { ProjectEditWrapper } from "../pages/projects/EditProject";
import { DrawingVersionWrapper } from "../pages/projects/AddDrawingVersion";
import { ImportFileWrapper } from "../pages/projects/ImportForm";
import ProjectDetailViewDisciplines from "../pages/projects/ProjectDetailViewDisciplines";
import ProjectDetailPages from "../pages/projects/ProjectDetailPages";
import DisciplineDetailData from "../pages/projects/DisciplineDetailData";
import MasterManagementPages from "../pages/projects/ProjectMasterManagement/MasterManagementPages";
import EditMasterLevels from "../pages/projects/ProjectMasterManagement/EditMasterLevels";
import ManagementPages from "../pages/projects/MaserManagement/ManagementPages";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/projects" />} />
        {/* Pages */}
        <Route path="projects" element={<DashboardWrapper />} />
        <Route path="project/add" element={<NewProjectWrapper />} />
        <Route
          path="project/:projectId/*"
          element={<ProjectDetailPages />}
        ></Route>
        <Route
          path="master-management/:userId/*"
          element={<ManagementPages />}
        ></Route>
        <Route
          path="project/detail/:projectId/import"
          element={<ImportFileWrapper />}
        />
        <Route
          path="project/detail/:projectId/master-management/*"
          element={<MasterManagementPages />}
        ></Route>

        <Route
          path="project/:projectId/details/add"
          element={<AddProjectDetailWrapper />}
        />
        <Route
          path="project/:drawingId/drawing/edit"
          element={<ProjectDrawingEditWrapper />}
        />
        <Route
          path="project/:drawingId/drawing_version/edit"
          element={<DrawingVersionWrapper />}
        />
        <Route
          path="project/:projectId/edit"
          element={<ProjectEditWrapper />}
        />
        <Route
          path="project/detail/:projectId/master-management/levels/:levelId/edit"
          element={
            <SuspensedView>
              <EditMasterLevels />
            </SuspensedView>
          }
        />

        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
