/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { supabase } from "../../modules/auth/components/supabase";
import { KTIcon, KTSVG } from "../../../_metronic/helpers";
import { Button, Modal } from "react-bootstrap";
import { Search } from "../../../_metronic/partials";
import clsx from "clsx";
import * as React from "react";
import { QRCode } from "react-qrcode-logo";
import { ToastContainer, toast } from "react-toastify";
import style from "../projects/custom.module.css";
import SetNewPin from "./SetNewPin";

export interface Project {
  id: number;
  name: string;
  logo: string;
  drawingsCount: number;
  tags: [];
  description: string;
}

const Dashboard = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [logoUrls, setLogoUrls] = useState<Record<string, string>>({});
  const [showCopied, setShowCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string>("");
  const [searchTerm] = useState<string>("");
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [pageProject, setPageProject] = useState();
  const recordsPerPage = 50;

  const downloadQRCode = (url?: string, id?: string) => {
    console.log("url", url);
    console.log("id", id);
    setQrCodeDataUrl(url as string);
    // @ts-ignore
    const qrCodeCanvas = document.getElementById(id) as HTMLCanvasElement;
    const link = document.createElement("a");
    link.href = qrCodeCanvas?.toDataURL(url);
    link.download = "qrcode.png";
    link.click();
    console.log("qr code", qrCodeCanvas);
  };

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const organizationId = localStorage.getItem("organizationId");
      const {
        data: projects,
        error,
        count,
      } = await supabase
        .from("Projects")
        .select(`*, Drawings_v2_source(*), Organization(*)`, { count: "exact" })
        .eq(`organization_id`, organizationId)
        .order("id", { ascending: true })
        .range(
          (currentPage - 1) * recordsPerPage,
          (currentPage - 1) * recordsPerPage + recordsPerPage - 1
        );

      if (error) {
        throw error;
      }

      // @ts-ignore
      setProjects(projects);
      setCount(count!);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(count / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(count / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const fetchLogoUrls = async () => {
    const urls = await Promise.all(
      projects.map(async (project) => {
        const url = fetchSignedUrl(project.logo);
        return [project.logo, url] as [string, string];
      })
    );
    setLogoUrls(Object.fromEntries(urls));
  };

  const fetchSignedUrl = (file: string): string => {
    if (!file) {
      return "";
    }
    try {
      const { data } = supabase.storage.from("project-logo").getPublicUrl(file);
      return data!.publicUrl;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [currentPage]);

  useEffect(() => {
    if (projects) {
      fetchLogoUrls();
    }
  }, [projects]);

  const handleCopyLink = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const link = event.currentTarget.href;
    try {
      await navigator.clipboard.writeText(link);
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (projectId: number) => {
    try {
      const { data, error } = await supabase
        .from("project_levels")
        .delete()
        .eq("project_id", projectId);

      if (error) {
        throw error;
      }
      const { data: file_type, error: file_typeError } = await supabase
        .from("project_file_types")
        .delete()
        .eq("project_id", projectId);

      if (file_typeError) {
        throw file_typeError;
      }

      const { data: project_deliverables, error: project_deliverablesError } =
        await supabase
          .from("project_deliverables")
          .delete()
          .eq("project_id", projectId);

      if (project_deliverablesError) {
        throw project_deliverablesError;
      }
      const { data: project_disciplines, error: project_disciplinesError } =
        await supabase
          .from("project_disciplines")
          .delete()
          .eq("project_id", projectId);
      if (project_disciplinesError) {
        throw project_disciplinesError;
      }
      const { data: project_buildings, error: project_buildingsError } =
        await supabase
          .from("project_buildings")
          .delete()
          .eq("project_id", projectId);
      if (project_buildingsError) {
        throw project_buildingsError;
      }
      const {
        data: project_qrcode_template,
        error: project_qrcode_templateError,
      } = await supabase
        .from("project_qrcode_template")
        .delete()
        .eq("project_id", projectId);
      if (project_qrcode_templateError) {
        throw project_qrcode_templateError;
      }
      const { error: deleteError } = await supabase
        .from("Projects")
        .delete()
        .eq("id", projectId);

      if (deleteError) {
        throw deleteError;
      }

      window.location.reload();
    } catch (error) {
      // @ts-ignore
      toast.error(error.message);
    }
  };

  const getImageSrc = (project: Project) => {
    const logoUrl = logoUrls[project.logo];
    if (logoUrl) {
      return logoUrl;
    }
    const projectName = project.name.trim();
    const firstLetter = projectName[0]?.toUpperCase();
    return `https://dummyimage.com/200x200/000/eee&text=${firstLetter}`;
  };

  const toggleProjectActiveStatus = async (project: any) => {
    try {
      const { data, error } = await supabase
        .from("Projects")
        .update({ is_active: !project.is_active })
        .eq("id", project.id);
      if (error) {
        throw error;
      }

      const updatedProjects = projects.map((p) => {
        if (p.id === project.id) {
          // @ts-ignore
          return { ...p, is_active: !p.is_active };
        }
        return p;
      });
      setProjects(updatedProjects);
    } catch (error) {
      // @ts-ignore
      toast.error(error.message);
    }
  };

  const filteredProjects = projects.filter((project) => {
    const searchRegex = new RegExp(searchTerm, "i");
    return (
      searchRegex.test(project.name) ||
      searchRegex.test(project.description) ||
      project.tags.some((tag: string) => searchRegex.test(tag))
    );
  });

  const itemClass = "ms-1 ms-lg-3";

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={`card `}>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label">
              <span className="fw-bold fs-3 mb-1">Projects ({count})</span>
            </span>
          </h3>
          <div className="app-navbar flex-shrink-0">
            <div
              className={clsx("app-navbar-item align-items-stretch", itemClass)}
            >
              <Search projects={projects} />
            </div>
            <div
              className="card-toolbar"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
              title="Click to add a project"
            >
              <a
                href="/project/add"
                className="btn btn-sm btn-hover btn-light-primary"
              >
                <KTIcon iconName="plus" className="fs-3" />
                New Project
              </a>
            </div>
          </div>
        </div>

        <div className="card-body py-3">
          <div className="table-responsive">
            {loading ? (
              <div className={style.loader_spinner}></div>
            ) : (
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead className="table-light">
                  <tr className="fw-bold text-muted">
                    <th>Project Name</th>
                    <th>Total Drawings</th>
                    <th>Project Code</th>
                    <th>Tags</th>
                    <th>QR</th>
                    <th>Link</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {projects.map((project: any) => {
                    return (
                      <tr key={project.id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-45px me-5">
                              <img
                                src={getImageSrc(project)}
                                alt={project.name}
                              />
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                href={`/project/${project.id}`}
                                className="text-dark fw-bold text-hover-primary fs-5"
                              >
                                {project.name}
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                {project.description}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="text-dark fw-bold d-block fs-6">
                            {project?.Drawings_v2_source?.length}
                          </div>
                        </td>
                        <td>
                          <div className="text-dark fw-bold d-block fs-6">
                            {project.code}
                          </div>
                        </td>

                        <td>
                          <div className="text fw d-block fs-6">
                            {project.tags.join(", ")}
                          </div>
                        </td>

                        <td>
                          <div className="d-flex flex-column w-100 me-2">
                            <div className="symbol symbol-45px me-5">
                              <a
                                className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_1"
                                onClick={() => {
                                  // @ts-ignore
                                  setQrCodeDataUrl(project.url);
                                }}
                              >
                                <KTIcon iconName="eye" className="fs-1" />
                              </a>
                              <div className="d-none">
                                <QRCode
                                  value={project.url}
                                  size={350}
                                  logoImage={fetchSignedUrl(
                                    project.Organization?.logo
                                  )}
                                  logoHeight={64}
                                  logoWidth={128}
                                  removeQrCodeBehindLogo={true}
                                  enableCORS={true}
                                  ecLevel="H"
                                  id={project.id}
                                />
                              </div>
                              <div
                                className="modal fade"
                                tabIndex={-1}
                                id="kt_modal_1"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title">
                                        Scan this QR to view the project
                                        details.
                                      </h5>
                                      <div
                                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <KTSVG
                                          path="/media/icons/duotune/arrows/arr061.svg"
                                          className="svg-icon svg-icon-2x"
                                        />
                                      </div>
                                    </div>
                                    <div className="modal-body text-center">
                                      {qrCodeDataUrl && (
                                        <QRCode
                                          value={qrCodeDataUrl || project.url}
                                          size={350}
                                          logoImage={fetchSignedUrl(
                                            project.Organization?.logo
                                          )}
                                          logoHeight={64}
                                          logoWidth={128}
                                          removeQrCodeBehindLogo={true}
                                          enableCORS={true}
                                          ecLevel="H"
                                          id="myQRCode"
                                        />
                                      )}
                                    </div>
                                    <div className="modal-footer"></div>
                                  </div>
                                </div>
                              </div>
                              <a
                                className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                                onClick={() =>
                                  downloadQRCode(project.url, project.id)
                                }
                              >
                                <KTIcon iconName="file-down" className="fs-1" />
                              </a>
                            </div>
                          </div>
                        </td>

                        <td>
                          <a
                            href={
                              project.id
                                ? project.url
                                : `https://www.google.com`
                            }
                            target="_blank"
                            onClick={handleCopyLink}
                            title={
                              showCopied ? "Link copied to clipboard!" : ""
                            }
                          >
                            <KTIcon iconName="bookmark" className="fs-3" />
                          </a>
                        </td>

                        <td className="text-end">
                          <div className="d-flex justify-content-center flex-shrink-0">
                            <div className="symbol symbol-45px me-6">
                              <div className="form-check form-switch form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`project_${project.id}`}
                                  checked={project.is_active}
                                  onChange={() =>
                                    toggleProjectActiveStatus(project)
                                  }
                                />
                              </div>
                            </div>
                            {/* <a
                              href="#"
                              className={
                                project.pin
                                  ? "btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                                  : "btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                              }
                            >
                              <KTIcon iconName="lock" className="fs-3" />
                            </a> */}
                            <SetNewPin project={project} />
                            <a
                              href={`/project/${project.id}/edit`}
                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                            >
                              <KTIcon iconName="pencil" className="fs-3" />
                            </a>
                            <button
                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                              onClick={() => setDeleteProjectId(project.id)}
                            >
                              <KTIcon iconName="trash" className="fs-3" />
                            </button>
                            <Modal
                              show={deleteProjectId === project.id}
                              onHide={() => setDeleteProjectId(null)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  Delete Project and all its information?
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are you sure you want to delete this project?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={() => setDeleteProjectId(null)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    handleDelete(project.id);
                                    setDeleteProjectId(null);
                                  }}
                                  variant="danger"
                                >
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            <div>
              <ul className="pagination">
                <li
                  className={`page-item previous ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <a href="#" className="page-link" onClick={previousPage}>
                    <i className="previous"></i>
                  </a>
                </li>
                {/* @ts-ignore */}
                {pageNumbers.map((number) => (
                  <li
                    key={number + 1}
                    className={`page-item ${
                      currentPage === number ? "active" : ""
                    }`}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => paginate(number)}
                    >
                      {number}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item next ${
                    currentPage === Math.ceil(count / recordsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                >
                  <a href="#" className="page-link" onClick={nextPage}>
                    <i className="next"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardWrapper: FC = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export { DashboardWrapper };
