import React from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import ProjectDetailViewDrawing from "./ProjectDetailViewDrawing";
import ProjectDetailViewDisciplines from "./ProjectDetailViewDisciplines";
import { ProjectDetailWrapper } from "./ProjectDetailView";
import DisciplineDetailData from "./DisciplineDetailData";
import DeliverableTableData from "./DeliverableTableData";

const drawingBreadCrumbs: Array<PageLink> = [
  {
    title: "Profile",
    path: "/crafted/pages/profile/overview",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ProjectDetailPages = () => {
  //   const { id } = useParams();
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <Routes>
      <Route
        element={
          <>
            <ProjectDetailWrapper />
            <Outlet />
          </>
        }
      >
        <Route
          path="drawings"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>Dwawings</PageTitle>
              <ProjectDetailViewDrawing />
            </>
          }
        />
        <Route
          path="disciplines"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>
                Disciplines
              </PageTitle>
              <ProjectDetailViewDisciplines />
            </>
          }
        />
        <Route
          path="disciplines/:discipinesId"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>
                Disciplines
              </PageTitle>
              <DisciplineDetailData />
            </>
          }
        />
        <Route
          path="disciplines/:discipinesId/:deliverId/deliverables"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>
                Disciplines
              </PageTitle>
              <DeliverableTableData />
            </>
          }
        />
        <Route
          index
          element={<Navigate to={`/project/${projectId}/drawings`} />}
        />
      </Route>
    </Routes>
  );
};

export default ProjectDetailPages;
