/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { supabase } from "../../modules/auth/components/supabase";
import { hash } from "../../../utils/Helper/bcrypt";
import { validatePassword } from "../../../utils/Helper/validatePassword";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, MultiValue } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import classes from "./custom.module.css";

interface Tags {
  label: string;
  value: string;
}
const EditProject = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [is_active, setIsActive] = useState<boolean>(true);
  const [pin, setPin] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [tags, setTags] = useState<Tags[]>([]);
  const [projectTags, setProjectTags] = useState<Tags[]>([]);
  const [editProject, setEditProject] = useState(false);
  const [projectCode, setProjectCode] = useState<string>("");
  const [btnLoader, setBtnLoader] = useState(false);

  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getTags();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  useEffect(() => {
    async function fetchProjectDetails() {
      try {
        const { data: project, error } = await supabase
          .from("Projects")
          .select("*")
          .eq("id", projectId)
          .single();

        if (error) {
          throw error;
        }

        if (project) {
          setName(project.name);
          setDescription(project.description);
          setIsActive(project.is_active);
          setPin(project.pin);
          const projectTagsData = project.tags.map((tag: any) => {
            return { label: tag, value: tag };
          });
          setProjectTags(projectTagsData);
          setProjectCode(project.project_code);
        }
        console.log("project", project);

        console.log("projectCode", projectTags);
      } catch (error) {
        console.error("Error fetching drawing:", error);
      }
    }
    fetchProjectDetails();
  }, [projectId]);

  let selectedProjectTags: any = [];
  const handleTagsChange = async (
    selectedTags: MultiValue<Tags>,
    actionMeta: ActionMeta<Tags>
  ) => {
    const newTag = selectedTags[selectedTags.length - 1];
    selectedProjectTags = selectedTags;
    if (newTag && !tags.find((tag) => tag.value === newTag.value)) {
      try {
        const { data, error } = await supabase
          .from("Tags")
          .insert({ name: newTag.value });
        if (error) {
          throw error;
        }
        const newTagObj = { label: newTag.value, value: newTag.value };
        setTags([...tags, newTagObj]);
      } catch (error) {
        console.error("Error adding tag:", error);
      }
    }
  };

  const updateProject = async (e: any) => {
    e.preventDefault();
    setBtnLoader(true);
    try {
      setEditProject(true);
      const hashedPin = pin;
      const tagValues = selectedProjectTags.map((tag: any) => tag.value);

      let fileData, fileExtension;
      fileExtension = file?.name.split(".").pop();
      if (file) {
        const { data, error: logoError } = await supabase.storage
          .from("project-logo")
          .upload(`project_${Date.now()}.${fileExtension}`, file, {
            cacheControl: "3600",
            upsert: true,
          });

        if (logoError) {
          throw new Error(logoError.message);
        }

        fileData = data;
      }
      console.log(tagValues);

      const { data: projectData, error: updateError } = await supabase
        .from("Projects")
        .update({
          name,
          description,
          is_active,
          pin: hashedPin,
          logo: fileData?.path,
          tags: projectTags.map((tag) => tag.value),
          project_code: projectCode,
        })
        .eq("id", projectId);

      if (updateError) {
        throw new Error(updateError.message);
      } else {
        navigate("/projects");
        toast.success("Successfully edit project");
      }
      setBtnLoader(false);
    } catch (error) {
      console.error("Error updating project:", error);
      // @ts-ignore
      toast.error(error.message);
    }
  };

  const getTags = async () => {
    try {
      const { data: tags, error: tagsError } = await supabase
        .from("Tags")
        .select("*");
      if (tagsError) {
        throw new Error(tagsError.message);
      }
      const mappedTags = tags.map((tag) => {
        return {
          label: tag.name,
          value: tag.name,
        };
      });
      setTags(mappedTags);
    } catch (error) {
      // @ts-ignore
      toast.error(error.message);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={updateProject}>
        {" "}
        <div className="row">
          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Project Name</label>
            <input
              type="text"
              className="form-control form-control-white"
              placeholder="Type Project Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              // required
            />
          </div>
          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Project Code</label>
            <input
              type="text"
              className="form-control form-control-white"
              placeholder="Project code"
              value={projectCode}
              onChange={(e) => setProjectCode(e.target.value)}
              // required
            />
            {/* <div className="text-danger">
          {validationError && <span id="nameField">* Required field</span>}
        </div> */}
          </div>
          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Desciption</label>
            <textarea
              className="form-control form-control-white"
              placeholder="Project Desciption"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              // required
            />
          </div>
          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">PIN</label>
            <input
              type="text"
              className="form-control form-control-white"
              placeholder="Type Project Password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              // required
            />
          </div>
          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Tags</label>
            <CreatableSelect
              isMulti
              options={tags}
              value={projectTags}
              onChange={(e: any) => {
                setProjectTags(e);
              }}
              // onChange={handleTagsChange}
            />
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Is Active?</label>
            <div>
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  value="true"
                  id="trueRadio"
                  name="isActive"
                  checked={is_active}
                  onChange={(e) => setIsActive(e.target.value === "true")}
                />
                <label className="form-check-label" htmlFor="trueRadio">
                  TRUE
                </label>
              </div>
              <br />
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  value="false"
                  id="falseRadio"
                  name="isActive"
                  checked={!is_active}
                  onChange={(e) => setIsActive(e.target.value === "true")}
                />
                <label className="form-check-label" htmlFor="falseRadio">
                  FALSE
                </label>
              </div>
            </div>
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Logo</label>
            <input
              type="file"
              className="form-control form-control-dark"
              onChange={handleFileChange}
            />
          </div>
        </div>
        {btnLoader ? (
          <button
            // type="submit"
            disabled={editProject}
            className="btn btn-primary"
          >
            <p className="d-flex">
              <div className={classes.btn_loader}></div>
              Updating...
            </p>
          </button>
        ) : (
          <button
            type="submit"
            // onClick={updateProject}
            className="btn btn-primary"
          >
            Update Project
          </button>
        )}
      </form>
    </>
  );
};

const ProjectEditWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.PROJECT_EDIT" })}
      </PageTitle>
      <EditProject />
    </>
  );
};

export { ProjectEditWrapper };
