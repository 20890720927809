import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import MasterLevels from "./MasterLevels";
import MasterManagementDetails from "./MasterManagementDetails";
import MasterFileType from "./MasterFileType";
import MasterVolume from "./MasterVolume";
import MasterDisciplines from "./MasterDisciplines";
import MasterQrCode from "./MasterQrCode";

const drawingBreadCrumbs: Array<PageLink> = [
  {
    title: "Profile",
    path: "/crafted/pages/profile/overview",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ManagementPages = () => {
  const userId = localStorage.getItem("organizationId");

  console.log("USER ID =>", userId);

  return (
    <Routes>
      <Route
        element={
          <>
            <MasterManagementDetails />
            <Outlet />
          </>
        }
      >
        <Route
          path="levels"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>Levels</PageTitle>
              <MasterLevels />
            </>
          }
        />
        <Route
          path="file-type"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>File Type</PageTitle>
              <MasterFileType />
            </>
          }
        />
        <Route
          path="volume"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>Volume</PageTitle>
              <MasterVolume />
            </>
          }
        />
        <Route
          path="disciplines"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>
                Disciplines
              </PageTitle>
              <MasterDisciplines />
            </>
          }
        />
        <Route
          path="qrcode"
          element={
            <>
              <PageTitle breadcrumbs={drawingBreadCrumbs}>
                Qr Code
              </PageTitle>
              <MasterQrCode />
            </>
          }
        />
        <Route
          index
          element={<Navigate to={`/master-management/${userId}/levels`} />}
        />
      </Route>
    </Routes>
  );
};

export default ManagementPages;
