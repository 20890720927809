/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { supabase } from "../../modules/auth/components/supabase";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { forEach } from "react-bootstrap/ElementChildren";
import classes from "./custom.module.css";
interface DrawingType {
  label: string;
  value: string;
}

const organizationId = localStorage.getItem("organizationId");
const AddProjectDetail = () => {
  const [projectData, setProjectData] = useState({
    name: "",
    description: "",
  });
  const [validationError, setValidationError] = useState(false);
  const [validationError2, setValidationError2] = useState(false);
  const [validationErrorForLevel, setValidationErrorForLevel] = useState(false);
  const [validationErrorForFileType, setValidationErrorForFileType] =
    useState(false);
  const [validationErrorForDisciplines, setValidationErrorForDisciplines] =
    useState(false);
  const [validationErrorForVolume, setValidationErrorForVolume] =
    useState(false);
  const [validationErrorForTemplate, setValidationErrorForTemplate] =
    useState(false);
  const [validationErrorForFile, setValidationErrorForFile] = useState(false);

  // const [name, setName] = useState("");
  // const [description, setDescription] = useState("");
  const [file, setFile] = useState<File>();
  const [config, setConfig] = useState("");
  const [drawingTypes, setDrawingTypes] = useState<DrawingType[]>([]);
  const [selectedType, setSelectedType] = useState<string>("");
  const [drawingAdded, setDrawingAdded] = useState(false);
  const [levels, setLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState<any[]>([]);
  const [projectFileType, setProjectFileType] = useState<any[]>([]);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedFileTypeName, setSelectedFileTypeName] = useState("");
  const [projectDisciplines, setProjectDisciplines] = useState<any[]>([]);
  const [selectedDisciplines, setSelectedDisciplines] = useState("");
  const [projectDeliverables, setProjectDeliverables] = useState<any[]>([]);
  const [selectedDeliverables, setselectedDeliverables] = useState("");
  const [projectBuildings, setProjectBuildings] = useState<any[]>([]);
  const [projectTemplates, setProjectTemplates] = useState<any[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState("");
  const [selectedVolumeValidation, setSelectedVolumeValidation] = useState("");
  const [selectedTemplateValidation, setSelectedTemplateValidation] =
    useState("");
  const [selectedDisiplinesValidation, setSelectedDisiplinesValidation] =
    useState("");
  const [btnLoader, setBtnLoader] = useState(false);

  const [date, setDate] = useState<any>("");
  const [newDate, setNewDate] = useState("");
  // const [progress, setProgress] = useState<number>(0);

  const { projectId } = useParams();
  const navigate = useNavigate();

  const handleInput = (e: any) => {
    setProjectData({ ...projectData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    try {
      setFile(file!);
      // @ts-ignore
    } catch (error) {
      console.log(error);
    }
  };

  const generateUrl = async (drawingId: number) => {
    return `viewer/drawings/${drawingId}`;
  };

  // const fetchDrawingTypes = async () => {
  //   const { data: drawing_types, error: _ } = await supabase
  //     .from("Drawing_Types")
  //     .select("*");
  //   const types = drawing_types!.map((drawing_type: any) => ({
  //     value: drawing_type.id,
  //     label: drawing_type.name,
  //   }));
  //   // @ts-ignore
  //   setDrawingTypes(types);
  // };
  // const handleDateChange = async (selectedDate: any) => {
  //   setDate(selectedDate);
  // };

  // useEffect(() => {
  //   console.log("in effect date", date);
  //   if (date) {
  //     const formattedDate = format(date, "MM/dd/yyyy");
  //     console.log("formatted date", formattedDate);
  //     setNewDate(formattedDate);
  //   }
  // }, [date]);
  const handleDateChange = async (selectedDate: any) => {
    setDate(selectedDate);
  };

  useEffect(() => {
    console.log("in effect date", date);
    if (date) {
      const formattedDate = format(date, "MM/dd/yyyy");
      console.log("formatted date", formattedDate);
      setNewDate(formattedDate);
    }
  }, [date]);
  console.log("date", newDate);

  const handleLevelChange = async (selectedLevel: any, actionMeta: any) => {
    console.log("SELECTED LEVELS", selectedLevel);
    setSelectedLevels(selectedLevel);
  };

  const fetchLevels = async () => {
    const { data: levels, error } = await supabase
      .from("project_levels")
      .select("*")
      .eq("project_id", projectId);
    if (error) {
      throw error;
    }
    const newSetLevels = levels.map((level) => {
      return {
        label: level.name,
        value: level.id,
      };
    });
    // @ts-ignore
    setLevels(newSetLevels);
  };
  // console.log("id", selectedLevelId);

  // console.log("level data", levels);

  const fetchFileType = async () => {
    const { data: fileTypes, error } = await supabase
      .from("project_file_types")
      .select("*")
      .eq("project_id", projectId);
    console.log("projectId", projectId);

    if (error) {
      throw error;
    }
    const newSetLevels = fileTypes.map((file) => {
      return {
        label: file.name,
        value: file.id,
      };
    });
    // @ts-ignore
    setProjectFileType(newSetLevels);
    setSelectedFileType(newSetLevels[0].value);
  };

  console.log("file type id", selectedFileType);

  const fetchDisciplinesData = async () => {
    const { data: disciplines, error } = await supabase
      .from("project_disciplines")
      .select("*,project_deliverables(*)")
      .eq("project_id", projectId);
    if (error) {
      throw error;
    }
    const newDisciplinesData = disciplines.map((disciplines) => {
      let deliverablesArray: any = [];
      disciplines.project_deliverables.forEach((deliverable: any) => {
        deliverablesArray.push({
          label: deliverable.deliverable_name,
          value: deliverable.id,
        });
      });
      return {
        label: disciplines.discipline_name,
        value: disciplines.id,
        deliverables: deliverablesArray,
      };
    });
    // @ts-ignore
    setProjectDisciplines(newDisciplinesData);
    setSelectedDisciplines(newDisciplinesData[0].value);
  };

  console.log("disciplines id", selectedDisciplines);

  const fetchDeliverablesData = async () => {
    const { data: deliverables, error } = await supabase
      .from("project_deliverables")
      .select("*")
      .eq("project_id", projectId);
    if (error) {
      throw error;
    }
    const newdeliverablesData = deliverables.map((deliverables) => {
      return {
        label: deliverables.deliverable_name,
        value: deliverables.id,
      };
    });
    // @ts-ignore
    setProjectDeliverables(newdeliverablesData);
    setselectedDeliverables(newdeliverablesData[0].value);
  };
  console.log("deliverbles id", selectedDeliverables);

  const fetchSelectedBuildings = async () => {
    const { data: buildings, error } = await supabase
      .from("project_buildings")
      .select("*")
      .eq("project_id", projectId);
    if (error) {
      throw error;
    }
    const newBuildingsData = buildings.map((building) => {
      return {
        label: building.name,
        value: building.id,
      };
    });
    // @ts-ignore
    setProjectBuildings(newBuildingsData);
    setSelectedBuildings(newBuildingsData[0].value);
  };
  console.log("build  data", projectBuildings);
  const fetchSelectedTempate = async () => {
    const { data: teamplatesData, errorData }: any = await supabase
      .from("project_qrcode_template")
      .select("*")
      .eq("project_id", projectId);
    console.log("teamplatesData", teamplatesData);

    // const { data: teamplates, error } = await supabase
    //   .from("master_qrcode_template")
    //   .select("*");

    if (errorData) {
      throw errorData;
    }

    const newBuildingsData = teamplatesData.map((teamplates: any) => {
      return {
        label: teamplates.name,
        value: teamplates.id,
      };
    });
    // @ts-ignore
    setProjectTemplates(newBuildingsData);
    setSelectedTemplates(newBuildingsData[0].value);
  };
  console.log("build  data", projectTemplates);

  useEffect(() => {
    // fetchDrawingTypes();
    fetchLevels();
    fetchFileType();
    fetchDeliverablesData();
    fetchDisciplinesData();
    fetchSelectedBuildings();
    fetchSelectedTempate();
  }, []);

  useEffect(() => {
    console.log("SELECTED DISCIPLINE ID UPDATED ", selectedDisciplines);
    projectDisciplines.forEach((discipline: any) => {
      if (discipline.id === selectedDisciplines) {
        setProjectDeliverables(discipline.deliverables);
      }
    });
  }, [selectedDisciplines]);
  console.log("deliverable dataaa", projectDeliverables);
  const validateFields = () => {
    let isValid = true;
    const { name, description } = projectData;
    if (name.length < 1) {
      setValidationError(true);
      setDrawingAdded(false);
      isValid = false;
    } else {
      setValidationError(false);
    }

    // if (description.length < 1) {
    //   setValidationError2(true);
    //   setDrawingAdded(false);
    //   isValid = false;
    // } else {
    //   setValidationError2(false);
    // }

    if (selectedLevels.length < 1) {
      setValidationErrorForLevel(true);
      setDrawingAdded(false);
      isValid = false;
    } else {
      setValidationErrorForLevel(false);
    }

    if (selectedFileTypeName.length < 1) {
      setValidationErrorForFileType(true);
      setDrawingAdded(false);
      isValid = false;
    } else {
      setValidationErrorForFileType(false);
    }
    if (selectedDisiplinesValidation.length < 1) {
      setValidationErrorForDisciplines(true);
      setDrawingAdded(false);
      isValid = false;
    } else {
      setValidationErrorForDisciplines(false);
    }
    if (selectedVolumeValidation.length < 1) {
      setValidationErrorForVolume(true);
      isValid = false;
      setDrawingAdded(false);
    } else {
      setValidationErrorForVolume(false);
    }
    if (
      selectedFileTypeName === "2D Drawing (PDF)" &&
      selectedTemplateValidation.length < 1
    ) {
      isValid = false;
      setValidationErrorForTemplate(true);
      setDrawingAdded(false);
    } else {
      setValidationErrorForTemplate(false);
    }

    if (!file) {
      isValid = false;
      setValidationErrorForFile(true);
      setDrawingAdded(false);
    } else {
      setValidationErrorForFile(false);
    }
    return isValid;
  };
  const addProjectDetails = async (e: any) => {
    e.preventDefault();
    if (validateFields()) {
      setBtnLoader(true);
      const { name, description } = projectData;
      try {
        setDrawingAdded(true);
        let fileData, fileExtension;
        // @ts-ignore
        fileExtension = file?.name.split(".").pop();
        if (!file) {
          setDrawingAdded(false);
          console.error("Please fill the field");
          throw new Error("Please fill the field");
        } else {
          const { data, error: setError } = await supabase.storage
            .from("project-documents")
            // @ts-ignore
            .upload(`project_${Date.now()}_${file.name}`, file, {
              cacheControl: "3600",
              upsert: true,
            });
          // console.log(onProgress(file?.size, file?.size));

          if (setError) {
            throw new Error(setError.message);
          }

          fileData = data;
        }

        const { data, error: drawingsError } = await supabase
          .from("Drawings_v2_source")
          .insert({
            // name,
            // description,
            name,
            description,
            is_active: true,
            file: fileData?.path,
            project_id: projectId,
            // config,
            // type: "selectedType",

            organization_id: organizationId,
            file_type_id: selectedFileType,
            descipline_id: selectedDisciplines,
            deliverable_id: selectedDeliverables,
            drawing_date: newDate ? newDate : new Date(),
            building_id: selectedBuildings,
            template_id: selectedTemplates ? selectedTemplates : null,
          })
          .select();

        if (drawingsError) {
          throw new Error(drawingsError.message);
        }

        const { data: drawingVersionData, error: drawingVersionError } =
          await supabase
            .from("Drawing_Versions")
            .insert({
              // @ts-ignore
              drawing_id: data[0].id,
              description,
              version_number: 0,
              organization_id: organizationId,
              drawing_date: newDate ? newDate : new Date(),
              template_id: selectedTemplates ? selectedTemplates : null,
            })
            .select();

        // @ts-ignore
        const url = await generateUrl(data[0].id);

        const { data: updatedData, error: updateError } = await supabase
          .from("Drawings_v2_source")
          .update({
            // @ts-ignore
            current_version: drawingVersionData[0].id,
            url,
          })
          // @ts-ignore
          .eq("id", data[0].id);

        if (updateError) {
          throw new Error(updateError.message);
        } else {
          navigate(`/project/${projectId}`);
          toast.success("Successfully added project drawing ");
        }
        let levelData: any[] = [];
        selectedLevels.forEach((level) => {
          levelData.push({
            drawing_id: data[0].id,
            level_id: level.value,
          });
        });
        await supabase
          .from("drawing_project_levels_v2_source")
          .insert(levelData);
        setProjectData({
          name: "",
          description: "",
        });
        setBtnLoader(false);
        // setName("");
        // setDescription("");
        setFile(undefined);
        setConfig("");
        setDrawingTypes([]);
      } catch (error) {
        console.error("Error adding project:", error);
        // @ts-ignore
        toast.error(error.message);
        setBtnLoader(false);
      }
    } else {
      setDrawingAdded(false);
      console.error("Please fill the field");
      throw new Error("Please fill the field");
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={addProjectDetails}>
        <div className="row">
          <div className="mb-10 col-sm-12 col-md-6">
            <label className="form-label">Name  {validationError && (
              <span id="nameField" className="text-danger">
                *
              </span>
            )}</label>
            <input
              // required
              type="text"
              className="form-control form-control-white"
              placeholder="Enter Name"
              name="name"
              value={projectData.name}
              onChange={handleInput}
            />
           
          </div>

          <div className="mb-10 col-sm-12 col-md-6">
            <label className="form-label">Desciption {validationError2 && <span className="text-danger">*</span>}</label>
            <textarea
              // required
              className="form-control form-control-white"
              placeholder="Type Desciption"
              name="description"
              value={projectData.description}
              onChange={handleInput}
            />
              
          </div>
        </div>

        <div className="row">
          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Levels {validationErrorForLevel && (
              <span className="text-danger">*</span>
            )}</label>
            <Select
              // required
              isMulti
              options={levels}
              onChange={handleLevelChange}
              // value={selectedLevel}
            />
            
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">File type {validationErrorForFileType && (
              <span className="text-danger">*</span>
            )}</label>
            <Select
              // required
              options={projectFileType}
              onChange={(selectedOption: any) => {
                console.log("VALUE", selectedOption);
                setSelectedFileType(selectedOption.value);
                setSelectedFileTypeName(selectedOption.label);
              }}
            />
            
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Disciplines {validationErrorForDisciplines && (
              <span className="text-danger">*</span>
            )}</label>
            <Select
              // required
              options={projectDisciplines}
              onChange={(selectedOption: any) => {
                setSelectedDisiplinesValidation(selectedOption.value);
                console.log("VALUE", selectedOption);

                setSelectedDisciplines(selectedOption.value);
                setProjectDeliverables(selectedOption.deliverables);
              }}
            />
            
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Deliverables</label>
            <Select
              options={projectDeliverables}
              onChange={(selectedOption: any) => {
                console.log("VALUE", selectedOption);
                setselectedDeliverables(selectedOption.value);
              }}
            />
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Volume {validationErrorForVolume && (
              <span className="text-danger">*</span>
            )}</label>
            <Select
              // required
              options={projectBuildings}
              onChange={(selectedOption: any) => {
                setSelectedVolumeValidation(selectedOption.value);
                console.log("VALUE", selectedOption);
                setSelectedBuildings(selectedOption.value);
              }}
            />
            
          </div>
          {selectedFileTypeName == "2D Drawing (PDF)" && (
            <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
              <label className="form-label">Template {validationErrorForTemplate && (
                <span className="text-danger">*</span>
              )}</label>
              <Select
                // required
                options={projectTemplates}
                onChange={(selectedOption: any) => {
                  setSelectedTemplateValidation(selectedOption.value);
                  console.log("VALUE", selectedOption);
                  setSelectedTemplates(selectedOption.value);
                }}
              />
              
            </div>
          )}

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <div>
              <label className="form-label">Date</label>
            </div>
            <DatePicker
              className="form-control col-sm-12 col-md-6 col-lg-4"
              // @ts-ignore
              selected={date ? newDate : new Date()}
              onChange={handleDateChange}
            />
          </div>

          <div className="mb-10 col-sm-12 col-md-6 col-lg-4">
            <label className="form-label">Add Files {validationErrorForFile && (
              <span className="text-danger">*</span>
            )}</label>
            <input
              // required
              type="file"
              className="form-control form-control-dark"
              onChange={handleFileChange}
            />
            
          </div>
        </div>

        {/* <div className="mb-10">
          <label className="form-label">Discipline</label>
          <Select
            options={drawingTypes}
            onChange={(option) => setSelectedType(option!.value)}
          />
        </div> */}

        {btnLoader ? (
          <button
            type="submit"
            disabled={drawingAdded}
            className="btn btn-primary"
          >
            <p className="d-flex">
              <div className={classes.btn_loader}></div> Uploading...
            </p>
          </button>
        ) : (
          <button
            type="submit"
            // disabled={drawingAdded}
            className="btn btn-primary"
          >
            Add Drawing
          </button>
        )}
      </form>
    </>
  );
};

const AddProjectDetailWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DRAWING" })}
      </PageTitle>

      <AddProjectDetail />
    </>
  );
};

export { AddProjectDetailWrapper };
