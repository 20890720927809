import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { supabase } from "../../modules/auth/components/supabase";
import style from "./custom.module.css";
import { format } from "date-fns";

interface UpdateDrawingPopupProps {
  projectId?: any;
  drawingId?: any;
  onClose: () => void;
}

const UpdateDrawingPopup = ({
  projectId,
  drawingId,
  onClose,
}: UpdateDrawingPopupProps) => {
  const [description, setDescription] = useState("");
  const [date, setDate] = useState<any>("");
  const [file, setFile] = useState<File>();
  const [drawingVersionAdd, setDrawingVersionAdd] = useState(false);
  const [newDate, setNewDate] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    if (date) {
      const formattedDate = format(date, "MM/dd/yyyy");
      console.log("formatted date", formattedDate);
      setNewDate(formattedDate);
    }
  }, [date]);
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setBtnLoader(true);
    try {
      setDrawingVersionAdd(true);
      let fileData, fileExtension;
      fileExtension = file?.name.split(".").pop();
      if (!file) {
        setDrawingVersionAdd(false);
        console.error("Please select a file");
        throw new Error("Please select a file");
      } else {
        const { data, error } = await supabase.storage
          .from("project-documents")
          .upload(`project_${Date.now()}_${file.name}`, file, {
            cacheControl: "3600",
            upsert: true,
          });

        if (error) {
          toast.error(error.message);
          throw error;
        }

        fileData = data;
      }
      console.log("drawingId", drawingId);

      const { data: V2Data, error: V2error }: any = await supabase
        .from("Drawings_v2_source")
        .select("*")
        .eq("id", drawingId);
      const { data: drawingVersionData, error: insertError } = await supabase
        .from("Drawing_Versions")
        .update({
          description,
          //   @ts-ignore
          //   version_number: currentVersion,
          //   drawing_id: drawingId,
          file: fileData?.path,
          //   organization_id: organizationId,
          drawing_date: newDate ? newDate : new Date(),
        })
        .eq("id", V2Data[0].current_version)
        .select();

      if (insertError) {
        throw new Error(insertError.message);
      }

      const { data: drawingData, error: drawingError } = await supabase
        .from("Drawings_v2_source")
        .update({
          // @ts-ignore
          description: drawingVersionData[0].description,
          //   current_version: drawingVersionData[0].id,
          file: fileData?.path,
          updated_at: drawingVersionData[0].created_at,
        })
        .eq("id", drawingId);

      if (drawingError) {
        throw new Error(drawingError.message);
      } else {
        toast.success("Successfully added drawing version");
      }
      setBtnLoader(false);
      window.location.reload();
    } catch (error) {
      console.error("Error adding drawing version:", error);
      // @ts-ignore
      toast.error(error.message);
      setBtnLoader(false);
    }
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Drawing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">File</label>
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>
          {btnLoader ? (
            <button
              type="submit"
              disabled={drawingVersionAdd}
              className="btn btn-primary"
            >
              <div className="d-flex">
                <p className={style.btn_loader}></p>
                Please wait...
              </div>
            </button>
          ) : (
            <button
              type="submit"
              // onClick={addProject}
              // disabled={projectAdded}
              className="btn btn-primary"
            >
              Add Drawing version
            </button>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDrawingPopup;
