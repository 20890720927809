import React, { useEffect, useState } from "react";
import { KTIcon, KTSVG } from "../../../_metronic/helpers";
import { QRCode } from "react-qrcode-logo";
import { Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { supabase } from "../../modules/auth/components/supabase";
import { ToastContainer, toast } from "react-toastify";
import UpdateDrawingPopup from "./updateDrawingData";

interface Drawing {
  id: string;
  name: string;
  url: string;
  description: string;
  file: string;
  is_active: boolean;
}

interface Project {
  id: string;
  name: string;
  description: string;
  logo: string;
  drawings: Drawing[];
  tags: string[];
  url: string;
}

const DeliverableTableData = () => {
  const { projectId, deliverId } = useParams<any>();
  const [project, setProject] = useState<Project | null>(null);
  const [showCopied, setShowCopied] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [drawingTypes, setDrawingTypes] = useState([]);
  const [drawingVersion, setDrawingVersion] = useState([]);
  const [versionHistory, setVersionHistory] = useState<any>([]);
  const [deleteDrawingId, setDeleteDrawingId] = useState(null);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(15);
  const [count, setCount] = useState<number>(0);
  const [drawings, setDrawings] = useState([]);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
  };

  const projectQrUrl = "http://localhost:3012/viewer/projects";

  const setQrCode = (url: string) => {
    setQrCodeDataUrl(url);
  };

  const downloadQRCode = (url?: string, id?: string) => {
    console.log("url", url);
    console.log("id", id);

    if (!id) {
      console.error("Invalid ID provided");
      return;
    }

    setQrCodeDataUrl(url as string);
    const qrCodeCanvas = document.getElementById(id) as HTMLCanvasElement;

    if (!qrCodeCanvas) {
      console.error("Element not found");
      return;
    }

    const link = document.createElement("a");
    link.href = qrCodeCanvas.toDataURL(url);
    link.download = "qrcode.png";
    link.click();
  };

  const fetchSignedUrl = (file: string): string => {
    if (!file) {
      return "";
    }
    try {
      const { data } = supabase.storage.from("project-logo").getPublicUrl(file);
      return data!.publicUrl;
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  useEffect(() => {
    const fetchDrawingTypes = async () => {
      // console.log("data  show");

      try {
        const { data, error } = await supabase
          .from("Drawing_Types")
          .select("*");

        if (error) {
          throw error;
        }

        // @ts-ignore
        setDrawingTypes(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDrawingTypes();
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const { data: projectData, error: projectError } = await supabase
          .from("Projects")
          .select("*")
          .eq("id", projectId)
          .single();

        if (projectError) {
          throw projectError;
        }

        const url = fetchSignedUrl(projectData!.logo);
        setLogoUrl(url);

        const {
          data: drawingsData,
          error: drawingsError,
          count,
        } = await supabase
          .from("Drawings_v2_source")
          .select(
            `*, Organization(*), Drawing_Versions(*), project_deliverables(*),project_disciplines(*)`,
            { count: "exact" }
          )
          .eq("project_id", projectId)
          .eq("deliverable_id", deliverId)
          .order("id", { ascending: true })
          .range(
            (currentPage - 1) * recordsPerPage,
            (currentPage - 1) * recordsPerPage + recordsPerPage - 1
          );

        // console.log("data", drawingsData);
        // @ts-ignore
        setDrawings(drawingsData);

        const drawingV = drawingsData?.map((d: any) => {
          return d.Drawing_Versions;
        });
        // @ts-ignore
        setDrawingVersion(drawingV);
        setCount(count!);
        // @ts-ignore
        // setQrCode();

        if (drawingsError) {
          console.log(drawingsError);
          throw drawingsError;
        }

        // @ts-ignore
        setProject((prevProject) => {
          if (!prevProject) {
            return { ...projectData, drawings: drawingsData };
          }

          return {
            ...prevProject,
            ...projectData,
            drawings: [...prevProject.drawings, ...drawingsData],
          };
        });
      } catch (error) {
        console.log(error);
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchProject();
  }, [projectId, currentPage, recordsPerPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(count / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(count / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getImageSrc = (project: any) => {
    // @ts-ignore
    const url = logoUrl[project.logo];
    if (logoUrl) {
      return logoUrl;
    }
    const projectName = project.name.trim();
    const firstLetter = projectName[0].toUpperCase();
    return `https://dummyimage.com/200x200/000/eee&text=${firstLetter}`;
  };

  const handleCopyLink = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const link = event.currentTarget.href;
    try {
      await navigator.clipboard.writeText(link);
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (drawingId: string) => {
    const { error } = await supabase
      .from("Drawings_v2_source")
      .delete()
      .eq("id", drawingId);
    if (error) {
      console.error("Error deleting drawing:", error);
    } else {
      window.location.reload();
    }
  };

  const getVersionHistory = async (drawingId: number) => {
    const { data, error } = await supabase
      .from("Drawing_Versions")
      .select("*")
      .eq("drawing_id", drawingId);

    setVersionHistory(data);
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="card">
        <div className="d-flex justify-content-between align-items-center h-55px">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">Drawings</span>
            </h3>
          </div>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a drawing"
          >
            <a
              href={`/project/${projectId}/details/add`}
              className="btn btn-sm btn-light-primary"
            >
              <KTIcon iconName="plus" className="fs-3" />
              New Drawing
            </a>
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead className="table-light">
                <tr className="fw-bold text-muted">
                  <th className="min-w-150px">Drawing Name</th>
                  <th className="min-w-150px">Disciplines</th>
                  <th className="min-w-150px">Deliverable</th>
                  {/* <th className="min-w-150px">Drawing Type</th> */}
                  <th className="min-w-120px">QR</th>
                  <th className="min-w-150px">Files</th>
                  <th className="min-w-100px">Actions</th>
                </tr>
              </thead>

              <tbody>
                {drawings?.map((drawing: any) => (
                  <tr key={drawing.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary fs-6"
                          >
                            {drawing.name}
                          </a>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">
                            {drawing.description}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="text-gray-700 mb-0 fw-semibold">
                        {drawing.project_disciplines.discipline_name}
                      </p>
                    </td>
                    <td>
                      <p className="text-gray-700 mb-0 fw-semibold">
                        {drawing.project_deliverables.deliverable_name}
                      </p>
                    </td>

                    {/* <td>
                      <div className="d-flex flex-column w-100 me-2">
                        {
                          drawingTypes?.find(
                            (type: any) => type.id === drawing.type
                            // @ts-ignore
                          )?.name
                        }
                      </div>
                    </td> */}

                    <td>
                      <div className="d-flex flex-column w-100 me-2">
                        <div className="symbol symbol-45px me-5">
                          <a
                            className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1"
                            // @ts-ignore
                            onClick={() => setQrCode(drawing.url)}
                          >
                            <KTIcon iconName="eye" className="fs-1" />
                          </a>

                          <a
                            // data-bs-toggle="modal bg-none"
                            // data-bs-target="#kt_modal_1"
                            className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                            // @ts-ignore
                            onClick={() =>
                              downloadQRCode(drawing.url, drawing.id)
                            }
                          >
                            <KTIcon iconName="file-down" className="fs-1" />
                          </a>
                          {/* ------------------------------------ */}

                          <div className="d-none">
                            <QRCode
                              value={drawing.url}
                              size={350}
                              logoImage={fetchSignedUrl(
                                drawing.Organization?.logo
                              )}
                              logoHeight={64}
                              logoWidth={128}
                              removeQrCodeBehindLogo={true}
                              enableCORS={true}
                              ecLevel="H"
                              id={drawing.id}
                            />
                          </div>

                          {/* ---------------------------------------- */}

                          <div
                            className={`modal fade`}
                            tabIndex={-1}
                            id="kt_modal_1"
                          >
                            <div className={`modal-dialog`}>
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Scan this QR to view the project details.
                                  </h5>
                                  <div
                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/arrows/arr061.svg"
                                      className="svg-icon svg-icon-2x"
                                    />
                                  </div>
                                </div>
                                <div className="modal-body text-center">
                                  {qrCodeDataUrl && (
                                    <div className="modal-body text-center">
                                      {qrCodeDataUrl && (
                                        <QRCode
                                          value={qrCodeDataUrl || drawing.url}
                                          size={350}
                                          logoImage={fetchSignedUrl(
                                            drawing.Organization?.logo
                                          )}
                                          logoHeight={64}
                                          logoWidth={128}
                                          removeQrCodeBehindLogo={true}
                                          enableCORS={true}
                                          ecLevel="H"
                                          id="myQRCode"
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="modal-footer"></div>
                              </div>
                            </div>
                          </div>

                          <a
                            href={
                              drawing.id
                                ? drawing.url
                                : `https://www.google.com`
                            }
                            className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                            target="_blank"
                            onClick={handleCopyLink}
                            title={
                              showCopied ? "Link copied to clipboard!" : ""
                            }
                          >
                            <KTIcon iconName="bookmark" className="fs-3" />
                          </a>
                          <a
                            href={drawing.url}
                            className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                            target="_blank"
                          >
                            <KTIcon
                              iconName="notepad-bookmark"
                              className="fs-3"
                            />
                          </a>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-column w-100 me-2">
                        <div>
                          R
                          {
                            drawingVersion?.find(
                              (version: any) =>
                                version.id === drawing.current_version
                              // @ts-ignore
                            )?.version_number
                          }
                          <button
                            className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_2"
                            onClick={() => getVersionHistory(drawing.id)}
                          >
                            <KTIcon iconName="eye" className="fs-1" />
                          </button>
                          <div
                            className="modal fade"
                            tabIndex={-1}
                            id="kt_modal_2"
                          >
                            <div
                              className="modal-dialog"
                              style={{ maxWidth: 800 }}
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <div>
                                    <h5 className="modal-title">
                                      Drawing Version History
                                    </h5>
                                    {showEditPopup ? null : (
                                      <a
                                      href={`/project/${drawing.id}/drawing_version/edit?projectId=${projectId}`}
                                      className="btn btn-sm btn-light-primary me-10"
                                    >
                                      <KTIcon
                                        iconName="plus"
                                        className="fs-3"
                                      />
                                      Add
                                    </a>
                                    )}
                                    
                                  </div>

                                  <div
                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/arrows/arr061.svg"
                                      className="svg-icon svg-icon-2x"
                                    />
                                  </div>
                                </div>
                                <div className="modal-body pt-0 pb-0">
                                  <div
                                    style={{
                                      overflowX: "scroll",
                                      maxHeight: 600,
                                    }}
                                  >
                                    <table className="table table-row-dashed table-row-gray-300 ">
                                      <thead
                                        style={{
                                          borderBottom: "1px dashed #dee2e6",
                                        }}
                                      >
                                        <tr className="fw-bolder fs-6 text-gray-800">
                                          <th className="text-center">Rev</th>
                                          <th className="text-center">Date</th>
                                          <th className="text-center">
                                            Description
                                          </th>
                                          <th className="text-center">File</th>
                                          <th className="text-center">QR</th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        style={{
                                          borderBottom: "1px dashed #dee2e6",
                                        }}
                                      >
                                        {versionHistory
                                          .sort(
                                            (a: any, b: any) =>
                                              b.version_number -
                                              a.version_number
                                          )
                                          .map(
                                            (version: any, index: number) => (
                                              <tr key={version.id}>
                                                <td className="text-center align-middle">
                                                  R{version.version_number}
                                                </td>
                                                <td className="text-center align-middle">
                                                  {`${new Date(
                                                    version.created_at
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      year: "2-digit",
                                                      month: "short",
                                                      day: "numeric",
                                                    }
                                                  )}`}
                                                </td>
                                                <td className="text-center align-middle">
                                                  {version.description
                                                    ? version.description
                                                    : "--"}
                                                </td>
                                                <td className="text-center align-middle">
                                                  {version.file
                                                    ? version.file
                                                    : "--"}
                                                </td>
                                                <td className="text-center align-middle">
                                                  {version.url !== null &&
                                                  version.config !== null ? (
                                                    <>
                                                      <a
                                                        className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_1"
                                                        // @ts-ignore
                                                        onClick={() =>
                                                          setQrCode(version.url)
                                                        }
                                                      >
                                                        <KTIcon
                                                          iconName="eye"
                                                          className="fs-1"
                                                        />
                                                      </a>
                                                      <a
                                                        // data-bs-toggle="modal bg-none"
                                                        // data-bs-target="#kt_modal_1"
                                                        className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                                                        // @ts-ignore
                                                        onClick={() =>
                                                          downloadQRCode(
                                                            version.url,
                                                            version.id
                                                          )
                                                        }
                                                      >
                                                        <KTIcon
                                                          iconName="file-down"
                                                          className="fs-1"
                                                        />
                                                      </a>
                                                      {index === 0 ? (
                                                        <a
                                                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                                          onClick={
                                                            handleEditClick
                                                          }
                                                        >
                                                          <KTIcon
                                                            iconName="pencil"
                                                            className="fs-3"
                                                          />
                                                        </a>
                                                      ) : null}
                                                      {showEditPopup && (
                                                        <UpdateDrawingPopup
                                                          projectId={projectId}
                                                          drawingId={drawing.id}
                                                          onClose={
                                                            handleCloseEditPopup
                                                          }
                                                        />
                                                      )}
                                                      {/* ------------------------------------ */}

                                                      <div className="d-none">
                                                        <QRCode
                                                          value={version.url}
                                                          size={350}
                                                          logoImage={fetchSignedUrl(
                                                            drawing.Organization
                                                              ?.logo
                                                          )}
                                                          logoHeight={64}
                                                          logoWidth={128}
                                                          removeQrCodeBehindLogo={
                                                            true
                                                          }
                                                          enableCORS={true}
                                                          ecLevel="H"
                                                          id={version.id}
                                                        />
                                                      </div>
                                                      <div
                                                        className={`modal fade`}
                                                        tabIndex={-1}
                                                        id="kt_modal_1"
                                                      >
                                                        <div
                                                          className={`modal-dialog`}
                                                        >
                                                          <div className="modal-content">
                                                            <div className="modal-header">
                                                              <h5 className="modal-title">
                                                                Scan this QR to
                                                                view the project
                                                                details.
                                                              </h5>
                                                              <div
                                                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                              >
                                                                <KTSVG
                                                                  path="/media/icons/duotune/arrows/arr061.svg"
                                                                  className="svg-icon svg-icon-2x"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="modal-body text-center">
                                                              {qrCodeDataUrl && (
                                                                <div className="modal-body text-center">
                                                                  {qrCodeDataUrl && (
                                                                    <QRCode
                                                                      value={
                                                                        qrCodeDataUrl ||
                                                                        version.url
                                                                      }
                                                                      size={350}
                                                                      logoImage={fetchSignedUrl(
                                                                        drawing
                                                                          .Organization
                                                                          ?.logo
                                                                      )}
                                                                      logoHeight={
                                                                        64
                                                                      }
                                                                      logoWidth={
                                                                        128
                                                                      }
                                                                      removeQrCodeBehindLogo={
                                                                        true
                                                                      }
                                                                      enableCORS={
                                                                        true
                                                                      }
                                                                      ecLevel="H"
                                                                      id="myQRCode"
                                                                    />
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                            <div className="modal-footer"></div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : null}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                      <tbody></tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <a
                        href={`/project/${drawing.id}/drawing/edit?projectId=${projectId}`}
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      >
                        <KTIcon iconName="pencil" className="fs-3" />
                      </a>

                      <button
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        onClick={() => setDeleteDrawingId(drawing.id)}
                      >
                        <KTIcon iconName="trash" className="fs-3" />
                      </button>
                      <Modal
                        show={deleteDrawingId === drawing.id}
                        onHide={() => setDeleteDrawingId(null)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Delete Drawing</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to delete this drawing?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setDeleteDrawingId(null)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              handleDelete(drawing.id);
                              setDeleteDrawingId(null);
                            }}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <ul className="pagination">
                <li
                  className={`page-item previous ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <a
                    href="#"
                    className="page-link"
                    // @ts-ignore
                    onClick={previousPage}
                  >
                    <i className="previous"></i>
                  </a>
                </li>
                {/* @ts-ignore */}
                {pageNumbers.map((number, i) => (
                  <li
                    key={i}
                    className={`page-item ${
                      currentPage === number ? "active" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => paginate(number)}
                    >
                      {number}
                    </Link>
                  </li>
                ))}
                <li
                  className={`page-item next ${
                    currentPage === Math.ceil(count / recordsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                >
                  <a
                    href="#"
                    className="page-link"
                    // @ts-ignore
                    onClick={nextPage}
                  >
                    <i className="next"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliverableTableData;
