import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { KTIcon } from "../../../../_metronic/helpers";
import { supabase } from "../../../modules/auth/components/supabase";
import style from "./masterManagement.module.css";
import axios from "axios";
import MasterQrCodeTemplate from "./projectMasterQrCodeTemplate";

const AddMasterNewQrCodePdf = () => {
  //   const userId = localStorage.getItem("organizationId");
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const [file, setFile] = useState<File>();
  const [show, setShow] = useState(false);
  const [templateId, setTemplateId] = useState<any>();
  const [imageData, setImageData] = useState<any>();
  const [templateName, setTemplateName] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleRedirect = () => {
    // navigate(`/master-management/${userId}/qrcode/template`);
    setShowModal(true);
  };
  const handleShow = () => setShow(true);

  const uploadNewFileType = async (e: any) => {
    e.preventDefault();
    setLoader(true);

    try {
      let fileData, fileExtension;
      fileExtension = file?.name.split(".").pop();

      if (!file) {
        // setDrawingVersionAdd(false);
        console.error("Please select a file");
        throw new Error("Please select a file");
      } else {
        const { data, error } = await supabase.storage
          .from("project-documents")
          .upload(`template_${Date.now()}_${file.name}`, file, {
            cacheControl: "3600",
            upsert: true,
          });

        if (error) {
          toast.error(error.message);
          throw error;
        }

        fileData = data;
      }
      const fileNameData = {
        fileName: fileData.path,
      };

      const response = await axios.post(
        "https://api.bimx.avinashi.com/pdfConverter",
        fileNameData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.length > 0) {
        handleRedirect();
      }

      setImageData(response.data);

      const { data: qrcodeData, error: qrcodeError }: any = await supabase
        .from("project_qrcode_template")
        .insert({
          name: templateName,
          file: fileData?.path,
          project_id: projectId,
        })
        .select();
      setTemplateId(qrcodeData[0].id);

      if (qrcodeError) {
        // @ts-ignore
        throw new Error(qrcodeError);
      }

      setLoader(false);
      setShow(false);
      toast.success("File added successfully");
      // window.location.reload();
    } catch (error) {
      setShow(true);
      setLoader(false);
      // @ts-ignore
      toast.error(error.message);
      console.log("Error", error);
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    try {
      setFile(file!);
      // @ts-ignore
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveData = (data: any) => {
    setShowModal(data);
    window.location.reload();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>QR Code Template</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "750px", overflow: "hidden" }}>
            <MasterQrCodeTemplate
              onSave={handleSaveData}
              imageData={imageData}
              templateId={templateId}
            />
          </Modal.Body>
        </Modal>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Button variant="primary" onClick={handleShow}>
          <KTIcon iconName="plus" className="fs-3" />
          New Template
        </Button>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add new template details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={uploadNewFileType}>
            <div className="mb-3">
              <label className="form-label">Template Name</label>
              <input
                required
                type="text"
                className="form-control form-control-dark"
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Add Pdf</label>
              <input
                required
                type="file"
                className="form-control form-control-dark"
                onChange={handleFileChange}
              />
            </div>

            <div className="mt-5 d-flex justify-content-end">
              <Button
                variant="secondary"
                size="sm"
                className="me-3"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              {loader ? (
                <Button
                  className="d-flex"
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  <div className={style.btn_loader}></div>
                  Wait...
                </Button>
              ) : (
                <Button type="submit" variant="primary" size="sm">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMasterNewQrCodePdf;
