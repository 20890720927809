import React, { useEffect, useState } from "react";
import { Card4 } from "../../../_metronic/partials/content/cards/Card4";
import { supabase } from "../../modules/auth/components/supabase";
import { useNavigate, useParams } from "react-router-dom";

const DisciplineDetailData = () => {
  const { projectId, discipinesId } = useParams();
  const navigate = useNavigate();
  const [deliverablesData, setDeliverablesData] = useState<any>([]);
  const [sortData, setSortData] = useState<any[]>([]);

  const fetchDeliverablesData = async () => {
    const { data, error } = await supabase
      .from("project_deliverables")
      .select("*,Drawings_v2_source(count)")
      .eq("project_id", projectId)
      .eq("project_discipline_id", discipinesId);

    if (error) {
      throw error;
    }

    setDeliverablesData(data);
  };

  useEffect(() => {
    fetchDeliverablesData();
  }, []);

  useEffect(() => {
    if (sortData.length === 0) {
      const sortedData = [...deliverablesData].sort(
        (a, b) => b.Drawings_v2_source[0].count - a.Drawings_v2_source[0].count
      );
      setSortData(sortedData);
    }
  }, [sortData]);
  const getDeliverableTablePage = (id: any) => {
    navigate(
      `/project/${projectId}/disciplines/${discipinesId}/${id}/deliverables`
    );
  };

  return (
    <div className="row g-6 g-xl-9 mb-6 mt-5 mb-xl-9 ">
      {sortData.map((deliverable: any) => {
        const title = `${deliverable.deliverable_name} (${deliverable.Drawings_v2_source[0].count})`;
        return (
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-3"
            key={deliverable.id}
            onClick={() => getDeliverableTablePage(deliverable.id)}
          >
            <Card4
              icon="/media/svg/files/folder-document.svg"
              title={title}
              description=""
            />
          </div>
        );
      })}
    </div>
  );
};

export default DisciplineDetailData;
