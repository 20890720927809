import React, { useState } from "react";
import { supabase } from "../../../modules/auth/components/supabase";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { KTIcon } from "../../../../_metronic/helpers";
import style from "./management.module.css";

const AddNewDiscipline = () => {
  const userId = localStorage.getItem("organizationId");
  const [show, setShow] = useState(false);
  const [discipline, setDiscipline] = useState({
    name: "",
  });
  const [loader, setLoader] = useState(false);

  const handleInput = (e: any) => {
    setDiscipline({ ...discipline, [e.target.name]: e.target.value });
  };

  const handleShow = () => setShow(true);

  const uploadNewFileType = async (e: any) => {
    e.preventDefault();
    setLoader(true);

    try {
      const { data: disciplineData, error: addError } = await supabase
        .from("master_disciplines")
        .insert({
          discipline_name: discipline.name,
          organization_id: userId,
        })
        .eq("organization_id", userId);

      if (addError) {
        // @ts-ignore
        throw new Error(addError);
      }

      console.log("DATA", disciplineData);

      setDiscipline({
        name: "",
      });
      setLoader(false);
      setShow(false);
      toast.success("Discipline added successfully");
      window.location.reload();
    } catch (error) {
      setShow(true);
      setLoader(false);
      // @ts-ignore
      toast.error(error.message);
      console.log("Error", error);
    }
  };
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <div className="d-flex align-items-center justify-content-center">
        <Button variant="primary" onClick={handleShow}>
          <KTIcon iconName="plus" className="fs-3" />
          New Discipline
        </Button>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add new discipline details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={uploadNewFileType}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                name="name"
                value={discipline.name}
                onChange={handleInput}
                className="form-control"
                placeholder="Enter discipline name"
                required
              />
            </div>
            <div className="mt-5 d-flex justify-content-end">
              <Button
                variant="secondary"
                size="sm"
                className="me-3"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              {loader ? (
                <Button
                  className="d-flex"
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  <div className={style.btn_loader}></div>
                  Wait...
                </Button>
              ) : (
                <Button type="submit" variant="primary" size="sm">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewDiscipline;
