import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { supabase } from "../../../modules/auth/components/supabase";
import { toast } from "react-toastify";
import style from "./masterManagement.module.css";
import { Button, Modal } from "react-bootstrap";
import { KTIcon } from "../../../../_metronic/helpers";
import EditMasterDiscipline from "./EditMasterDiscipline";
import AddMasterDiscipline from "./AddMasterDiscipline";
import AddMasterDleiverables from "./AddMasterDleiverables";
import EditMasterDeliverable from "./EditMasterDeliverable";

const ProjectMasterDisciplines = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(5);
  const [count, setCount] = useState<number>(0);
  const [disciplines, setDisciplines] = useState<any[]>([]);
  const [loader, setLoader] = useState<any>(false);
  const [deleteDisciplineId, setDeleteDisciplineId] = useState(null);
  const [deleteDeliverablesId, setDeleteDeliverablesId] = useState<any>(false);
  const [deliverable_data, setDeliverableData] = useState<any>();

  useEffect(() => {
    setLoader(true);
    const fetchLevelsData = async () => {
      const {
        data: disciplines,
        error,
        count,
      } = await supabase
        .from("project_disciplines")
        .select("*,project_deliverables(*)", { count: "exact" })
        .eq("project_id", projectId)
        .range(
          (currentPage - 1) * recordsPerPage,
          (currentPage - 1) * recordsPerPage + recordsPerPage - 1
        );

      if (error) {
        // @ts-ignore
        throw new Error(error);
      }
      disciplines.forEach((discipline) => {
        discipline.project_deliverables.sort((a: any, b: any) =>
          a.code.localeCompare(b.code)
        );
      });
      console.log(disciplines);
      setCount(count!);
      console.log("LEVEL DATA", disciplines);
      setDisciplines(disciplines);
      setLoader(false);
    };

    fetchLevelsData();
  }, [projectId, currentPage, recordsPerPage]);

  console.log("Final  Data", disciplines);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(count / recordsPerPage); i++) {
    pageNumbers.push(i);
  }
  console.log("levell pages", pageNumbers);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(count / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDelete = async (disciplineId: string) => {
    console.log("delete id", disciplineId);

    const { error } = await supabase
      .from("project_disciplines")
      .delete()
      .eq("id", disciplineId);
    if (error) {
      console.error("Error deleting discipline:", error);
      toast.error(error.message);
    } else {
      window.location.reload();
      toast.success("successfuly deleted");
    }
  };

  const handleDeleteDeliverable = async (deliverableId: any) => {
    const { error } = await supabase
      .from("project_deliverables")
      .delete()
      .eq("id", deliverableId);
    if (error) {
      console.error("Error deleting discipline:", error);
      toast.error(error.message);
    } else {
      window.location.reload();
      toast.success("successfuly deleted");
    }
  };

  const handleDisciplineSelect = (deliverable: any) => {
    setDeliverableData(deliverable);
  };

  return (
    <>
      {loader && <div className={style.loader_spinner}></div>}
      <div className="card-body py-3 mt-5">
        <div className="table-responsive">
          <div
            className="d-flex justify-content-space-between border mb-5"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h4>Disciplines List</h4>
            <AddMasterDiscipline />
          </div>
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead className="table-light">
              <tr className="fw-bold text-muted">
                {/* <th>Id</th> */}
                <th className="min-w-150px">Name</th>
                <th className="min-w-100px">Actions</th>
              </tr>
            </thead>

            <tbody>
              {disciplines.map((discipline: any) => {
                return (
                  <tr key={discipline.id}>
                    {/* <td>{level.id}</td> */}
                    <td>
                      <div className="accordion col-10" id="accordionExample">
                        <div
                          className="accordion-item"
                          style={{ minWidth: "auto", maxWidth: "800px" }}
                        >
                          <h2 style={{ minWidth: "auto", maxWidth: "800px" }}>
                            <button
                              className="accordion-button collapsed bg-light"
                              style={{
                                minWidth: "auto",
                                maxWidth: "800px",
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${discipline.id}`}
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo"
                            >
                              {discipline.discipline_name}
                            </button>
                          </h2>
                          <div
                            id={discipline.id}
                            className="accordion-collapse collapse"
                          >
                            {/* ----------------------------------------------------------------- */}
                            <div className="card-body py-3 mt-5">
                              <div className="table-responsive m-5">
                                <div
                                  className="d-flex justify-content-space-between border mb-5"
                                  style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <h4>Deliverable List</h4>
                                  <AddMasterDleiverables id={discipline.id} />
                                </div>
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <thead className="table-light">
                                    <tr className="fw-bold text-muted">
                                      {/* <th>Id</th> */}
                                      <th className="min-w-150px">Name</th>
                                      <th className="min-w-150px">
                                        Short Code
                                      </th>
                                      <th className="min-w-100px">Actions</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {discipline.project_deliverables.map(
                                      (deliverable: any) => {
                                        return (
                                          <tr key={deliverable.id}>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                  <a
                                                    href="#"
                                                    className="text-dark fw-bold text-hover-primary fs-6"
                                                  >
                                                    {
                                                      deliverable.deliverable_name
                                                    }
                                                  </a>
                                                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                                                    {}
                                                  </span>
                                                </div>
                                              </div>
                                            </td>

                                            <td>
                                              <div className="d-flex flex-column w-100 me-2">
                                                {deliverable.code}
                                              </div>
                                            </td>
                                            <td>
                                              <button
                                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                                onClick={() =>
                                                  handleDisciplineSelect(
                                                    deliverable
                                                  )
                                                }
                                              >
                                                <EditMasterDeliverable
                                                  deliverable_data={deliverable}
                                                />
                                              </button>

                                              <button
                                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                onClick={() =>
                                                  setDeleteDeliverablesId(
                                                    deliverable.id
                                                  )
                                                }
                                              >
                                                <KTIcon
                                                  iconName="trash"
                                                  className="fs-3"
                                                />
                                              </button>
                                              <Modal
                                                show={
                                                  deleteDeliverablesId ===
                                                  deliverable.id
                                                }
                                                onHide={() =>
                                                  setDeleteDeliverablesId(null)
                                                }
                                              >
                                                <Modal.Header closeButton>
                                                  <Modal.Title>
                                                    Delete Deliverable data
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  Are you sure you want to
                                                  delete this deliverable data?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    variant="secondary"
                                                    onClick={() =>
                                                      setDeleteDeliverablesId(
                                                        null
                                                      )
                                                    }
                                                  >
                                                    Cancel
                                                  </Button>
                                                  <Button
                                                    variant="danger"
                                                    onClick={() => {
                                                      handleDeleteDeliverable(
                                                        deliverable.id
                                                      );
                                                      setDeleteDeliverablesId(
                                                        null
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* ----------------------------------- */}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="col-2">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <EditMasterDiscipline id={discipline.id} />
                      </a>

                      <button
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        onClick={() => setDeleteDisciplineId(discipline.id)}
                      >
                        <KTIcon iconName="trash" className="fs-3" />
                      </button>
                      <Modal
                        show={deleteDisciplineId === discipline.id}
                        onHide={() => setDeleteDisciplineId(null)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Delete Discipline</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to delete this discipline?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setDeleteDisciplineId(null)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              handleDelete(discipline.id);
                              setDeleteDisciplineId(null);
                            }}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <ul className="pagination">
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <a href="#" className="page-link" onClick={previousPage}>
                  <i className="previous"></i>
                </a>
              </li>
              {pageNumbers.map((number, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                >
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Link>
                </li>
              ))}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(count / recordsPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <a href="#" className="page-link" onClick={nextPage}>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectMasterDisciplines;
