import React, { useCallback, useEffect, useState } from "react";
import { KTIcon, KTSVG } from "../../../_metronic/helpers";
import { QRCode } from "react-qrcode-logo";
import { Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { supabase } from "../../modules/auth/components/supabase";
import { format, parse } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import style from "./custom.module.css";
import ReactDataGrid from "@inovua/reactdatagrid-community";
// import "@inovua/reactdatagrid-enterprise/index.css";
import "@inovua/reactdatagrid-community/index.css";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import moment from "moment/moment";
import filter from "@inovua/reactdatagrid-community/filter";
import SetDrawingPin from "./SetDrawingPin";
import UpdateDrawingPopup from "./updateDrawingData";
import Select from "react-select";
import axios from "axios";

const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
  levels: {
    name: "levels",
    operators: [
      {
        name: "customfilter",
        fn: ({ value, filterValue, data }: any) => {
          if (filterValue == undefined) return true;
          return value.includes(filterValue);
        },
      },
    ],
  },
  title: {
    name: "title",
    operators: [
      {
        name: "operator1",
        fn: ({ value, filterValue, data }: any) => {
          if (filterValue == undefined) return true;
          return (
            value.description === filterValue || value.name === filterValue
          );
        },
      },
    ],
  },
});

interface Drawing {
  id: string;
  name: string;
  url: string;
  description: string;
  file: string;
  is_active: boolean;
}

interface Project {
  id: string;
  name: string;
  description: string;
  logo: string;
  drawings: Drawing[];
  tags: string[];
  url: string;
}

const ProjectDetailViewDrawing = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<Project | null>(null);
  const [showCopied, setShowCopied] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [drawingTypes, setDrawingTypes] = useState([]);
  const [drawingVersion, setDrawingVersion] = useState([]);
  const [versionHistory, setVersionHistory] = useState<any>([]);
  const [deleteDrawingId, setDeleteDrawingId] = useState(null);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState<number>(15);
  const [count, setCount] = useState<number>(0);
  const [pageProduct, setPageProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loads, setLoads] = useState<any>({});
  const [levels, setLevels] = useState<any[]>([]);
  const [drawingType, setDrawingType] = useState<any[]>([]);
  const [fileType, setFileType] = useState<any[]>([]);
  const [deliverables, setDeliverables] = useState<any[]>([]);
  const [gridRef, setGridRef] = useState<any>(null);
  const [volume, setVolume] = useState<any[]>([]);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showVersionHistoryModal, setShowVersionHistoryModal] = useState(false);
  const [qrCodeId, setQrCodeId] = useState(null);
  const [versionHistoryId, setVersionHistoryId] = useState(null);
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [templateData, setTemplateData] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>("");
  const [templateQrCodeDataUrl, setTemplateQrCodeDataUrl] =
    useState<string>("");
  const [templateQrCodeDataId, setTemplateQrCodeDataId] = useState<any>();

  const [bullkBtnLoader, setBullkBtnLoader] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState<any>();

  const onSelectionChange = useCallback(({ selected, data }: any) => {
    const selectedArray = Object.values(selected);
    setSelectedData(selected === true ? data : selectedArray);
    setSelected(selected);
  }, []);

  const handleDownload = async () => {
    let formData = new FormData();
    try {
      setBullkBtnLoader(true);
      const { data, error } = await supabase
        .from("Projects")
        .select("*")
        .eq("id", selectedData[0].project_id);

      if (error) {
        throw error;
      }
      selectedData.forEach((item: any) => {
        const qrCodeCanvas = document.getElementById(
          item.id
        ) as HTMLCanvasElement;
        const urlData = qrCodeCanvas.toDataURL(item.url);

        // formData.append("file", urlData);
        formData.append("qr", item.template_id);
        formData.append("url", item.url);
        formData.append("pdf", item.Drawing_Versions.id);
      });
      axios
        .post("https://api.bimx.avinashi.com/addQcInPdfBulk", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          let modifiedPdf = response.data;

          let blob = new Blob([modifiedPdf], { type: "application/zip" });

          let url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = url;
          link.download = `${moment(new Date()).format("DD-MM-YYYY")}_${
            data[0].name
          }.zip`;
          link.click();
          setBullkBtnLoader(false);
          // setLoads({ ...loads, [id]: false });
          // handleCloseQRModal();
        })
        .catch((error) => {
          // setLoads(false);
          setBullkBtnLoader(false);
          toast.error("Somthing went wrong");
          console.error(error);
        });
    } catch (error) {
      setBullkBtnLoader(false);
      console.log(error, "error");
    }
  };
  const handleOpenQRModal: any = async (id: any, url: any) => {
    // setTemplateQrCodeDataId(id);
    // setTemplateQrCodeDataUrl(url);

    const { data, error } = await supabase
      .from("Drawing_Versions")
      .select("*")
      .eq("id", id);

    if (error) {
      throw error;
    }
    // if (data[0].template_id) {
    setLoads({ ...loads, [id]: true });
    const qrCodeCanvas = document.getElementById(id) as HTMLCanvasElement;
    if (!qrCodeCanvas) {
      console.error("Element not found");
      return;
    }
    const { data: templateUrl, error: templateError }: any = await supabase
      .from("Drawing_Versions")
      .select("*")
      .eq("id", id);

    if (templateUrl[0]) {
      if (templateError) {
        console.error("Error fetching template data:", templateError);
        return;
      }
      const { data: templateFileName, error: templateFileNameError }: any =
        await supabase
          .from("project_qrcode_template")
          .select("*")
          .eq("id", templateUrl[0].template_id);
      if (templateFileNameError) {
        console.error("Error fetching template data:", templateFileNameError);
        return;
      }

      const urlName = templateUrl[0].file;
      const parts = urlName.split("_");
      const lastPart = parts[2];
      const resultFileName = lastPart.split(".pdf")[0];

      const urlData = qrCodeCanvas.toDataURL(url);
      // const urlParts = templateUrl[0].url.split("/").slice(-2);
      const code = templateUrl[0].url.split("/").pop();
      let formData = new FormData();
      formData.append("file", urlData);
      formData.append("qr", data[0].template_id);
      formData.append("url", url);
      formData.append("pdf", id);

      axios
        .post("https://api.bimx.avinashi.com/addQcInPdf", formData, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let modifiedPdf = response.data;
          let blob = new Blob([modifiedPdf], { type: "application/pdf" });

          let url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = url;
          link.download = `${resultFileName}_${code}.pdf`;
          link.click();
          setLoads({ ...loads, [id]: false });
          handleCloseQRModal();
        })
        .catch((error) => {
          setLoads(false);
          toast.error("Somthing went wrong");
          console.error(error);
        });
    } else {
      setShowQRCodeModal(true);
    }
  };

  const handleCloseQRModal = () => {
    setShowQRCodeModal(false);
  };

  const [showEditPopup, setShowEditPopup] = useState(false);
  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
  };

  const projectQrUrl = "http://localhost:3012/viewer/projects";

  const setQrCode = async (url: string, id: any) => {
    await setQrCodeId(id);
    await setQrCodeDataUrl(url);
    await setShowQRModal(true);
  };

  const downloadQRCode = (url?: string, id?: string) => {
    if (!id) {
      console.error("Invalid ID provided");
      return;
    }

    setQrCodeDataUrl(url as string);
    const qrCodeCanvas = document.getElementById(id) as HTMLCanvasElement;
    if (!qrCodeCanvas) {
      console.error("Element not found");
      return;
    }

    const link = document.createElement("a");
    link.href = qrCodeCanvas.toDataURL(url);
    link.download = "qrcode.png";
    link.click();
  };

  const fetchSignedUrl = (file: string): string => {
    if (!file) {
      return "";
    }
    try {
      const { data } = supabase.storage.from("project-logo").getPublicUrl(file);
      return data!.publicUrl;
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  useEffect(() => {
    setLoading(true);
    const fetchDrawingTypes = async () => {
      try {
        const { data, error } = await supabase
          .from("project_disciplines")
          .select("*")
          .eq("project_id", projectId);

        if (error) {
          throw error;
        }

        // @ts-ignore
        setDrawingTypes(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDrawingTypes();
  }, []);

  useEffect(() => {
    const fetchLevels = async () => {
      const { data: levels, error } = await supabase
        .from("project_levels")
        .select("*")
        .eq("project_id", projectId);
      if (error) {
        throw error;
      }
      const newSetLevels = levels.map((level) => {
        return {
          label: level.name,
          id: level.name,
        };
      });
      // @ts-ignore
      setLevels(newSetLevels);
    };
    fetchLevels();
  }, []);

  useEffect(() => {
    const fetchDrawingType = async () => {
      const { data: drawingType, error } = await supabase
        .from("Drawing_Types")
        .select("*");
      // .eq("project_id", projectId);
      if (error) {
        throw error;
      }
      const newSetDrawingType = drawingType.map((type) => {
        return {
          label: type.name,
          id: type.name,
        };
      });
      // @ts-ignore
      setDrawingType(newSetDrawingType);
    };
    fetchDrawingType();
  }, []);

  useEffect(() => {
    const fetchFileType = async () => {
      const { data: fileType, error } = await supabase
        .from("project_file_types")
        .select("*")
        .eq("project_id", projectId);
      if (error) {
        throw error;
      }
      const newSetFileType = fileType.map((type) => {
        return {
          label: type.name,
          id: type.name,
        };
      });
      // @ts-ignore
      setFileType(newSetFileType);
    };
    fetchFileType();
  }, []);

  useEffect(() => {
    const fetchFileType = async () => {
      const { data: deliverables, error } = await supabase
        .from("project_deliverables")
        .select("*")
        .eq("project_id", projectId);
      if (error) {
        throw error;
      }
      const newSetDeliverables = deliverables.map((type) => {
        return {
          label: type.deliverable_name,
          id: type.deliverable_name,
        };
      });
      // @ts-ignore
      setDeliverables(newSetDeliverables);
    };
    fetchFileType();
  }, []);

  useEffect(() => {
    const fetchFileType = async () => {
      const { data: volume, error } = await supabase
        .from("project_buildings")
        .select("*")
        .eq("project_id", projectId);
      if (error) {
        throw error;
      }
      const newSetVolume = volume.map((type) => {
        return {
          label: type.name,
          id: type.name,
        };
      });
      // @ts-ignore
      setVolume(newSetVolume);
    };
    fetchFileType();
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const { data: projectData, error: projectError } = await supabase
          .from("Projects")
          .select("*")
          .eq("id", projectId)
          .single();

        if (projectError) {
          throw projectError;
        }

        const url = fetchSignedUrl(projectData!.logo);
        setLogoUrl(url);

        const {
          data: drawingsData,
          error: drawingsError,
          count,
        } = await supabase
          .from("Drawings_v2_source")
          .select(
            `*, Organization(*), Drawing_Versions(*),project_disciplines(discipline_name), drawing_project_levels_v2_source!fk_drawing_id(project_levels(name)),project_file_types(name),project_deliverables(deliverable_name),project_buildings(name)`,
            {
              count: "exact",
            }
          )
          .eq("project_id", projectId)
          .order("id", { ascending: false });

        const drawingDataWithOrganization = drawingsData?.map(
          (drawing: any) => {
            return {
              ...drawing,
              // organization: drawing.Organization?.name || "N/A",
              key: drawing.id,
              drawingObject: {
                name: drawing.name,
                description: drawing.description,
                id: drawing.id,
              },
              drawingObjectFiles: {
                id: drawing.id,
                version: drawing.Drawing_Versions,
                logo: drawing.Organization.logo,
                file: drawing.file,
              },
              drawingObjectActions: {
                id: drawing.id,
              },
              drawingObjectQR: {
                id: drawing.id,
                url: drawing.url,
                file: drawing.file,
                logo: drawing.Organization.logo,
              },

              name: drawing.name,
              drawingType: drawing.project_disciplines
                ? drawing.project_disciplines.discipline_name
                : "",
              uploadedOn: drawing.updated_at,

              level: drawing.drawing_project_levels_v2_source.map(
                (level: any) => {
                  return level.project_levels ? level.project_levels.name : "";
                }
              ),
              fileType: drawing.project_file_types
                ? drawing.project_file_types.name
                : "",
              deliverable: drawing.project_deliverables
                ? drawing.project_deliverables.deliverable_name
                : "",
              volume: drawing.project_buildings
                ? drawing.project_buildings.name
                : "",
            };
          }
        );
        // @ts-ignore
        setPageProduct(drawingDataWithOrganization);
        const drawingV = drawingsData?.map((d: any) => {
          return d.Drawing_Versions;
        });
        // @ts-ignore
        setDrawingVersion(drawingV);
        setCount(count!);
        // @ts-ignore
        // setQrCode();

        if (drawingsError) {
          console.log(drawingsError);
          throw drawingsError;
        }

        // @ts-ignore
        setProject((prevProject) => {
          if (!prevProject) {
            return { ...projectData, drawings: drawingsData };
          }

          return {
            ...prevProject,
            ...projectData,
            drawings: [...prevProject.drawings, ...drawingsData],
          };
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchProject();
  }, [projectId, currentPage, recordsPerPage]);

  const [singleObj, setSingleObj] = useState(null);
  const setSingleDrawing = (id: any) => {
    pageProduct.map((drawing: any) => {
      if (drawing.id === id) {
        setSingleObj(drawing);
      } else {
      }
    });
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(count / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(count / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getImageSrc = (project: any) => {
    // @ts-ignore
    const url = logoUrl[project.logo];
    if (logoUrl) {
      return logoUrl;
    }
    const projectName = project.name.trim();
    const firstLetter = projectName[0].toUpperCase();
    return `https://dummyimage.com/200x200/000/eee&text=${firstLetter}`;
  };

  const handleCopyLink = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const link = event.currentTarget.href;
    try {
      await navigator.clipboard.writeText(link);
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
      setShowQRModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (drawingId: string) => {
    const { error } = await supabase
      .from("Drawings_v2_source")
      .delete()
      .eq("id", drawingId);
    if (error) {
      console.error("Error deleting drawing:", error);
      toast.error(error.message);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    const fetchTemplateData = async () => {
      const { data: template, error: templateError }: any = await supabase
        .from("master_qrcode_template")
        .select();
      const templateList = template.map((template: any) => {
        return {
          label: template.name,
          id: template.id,
        };
      });
      // @ts-ignore
      setTemplateData(templateList);
    };
    fetchTemplateData();
  }, []);

  const getVersionHistory = async (drawingId: number) => {
    const { data, error } = await supabase
      .from("Drawing_Versions")
      .select("*")
      .eq("drawing_id", drawingId);
    if (data) setVersionHistory(data);
  };

  function processFilename(filename: any) {
    var parts = filename.split("_");
    if (parts.length > 2) {
      return parts.slice(2).join("_");
    } else {
      return filename;
    }
  }
  const filterValue = [
    { name: "name", operator: "contains", type: "string", value: null },
    { name: "drawingType", operator: "eq", type: "select", value: null },
    // {name: "uploadedOn", operator: "before", type: "date", value: ""},
    { name: "level", operator: "customfilter", type: "levels", value: null },
    { name: "fileType", operator: "eq", type: "select", value: null },
    { name: "deliverable", operator: "eq", type: "select", value: null },
    { name: "volume", operator: "eq", type: "select", value: null },
  ];

  const columns = [
    {
      name: "name",
      header: "Name",
      defaultFlex: 1,
    },
    {
      name: "uploadedOn",
      header: "Uploaded on",
      defaultFlex: 1,
      render: ({ value }: any) => {
        return moment(value).format("DD-MM-YYYY");
      },
    },
    {
      name: "level",
      header: "Level",
      defaultFlex: 1,
      filterEditor: SelectFilter,

      filterEditorProps: {
        placeholder: "All",
        dataSource: levels,
      },
      render: ({ value }: any) => value.join(" , "),
    },
    {
      name: "fileType",
      header: "File Type",
      defaultFlex: 1,
      filterEditor: SelectFilter,
      placeholder: "All",
      filterEditorProps: {
        placeholder: "All",
        dataSource: fileType,
      },
      render: ({ value }: any) => (value.level ? value.label : value),
    },
    {
      name: "drawingType",
      header: "Discipline",
      defaultFlex: 1,
      filterEditor: SelectFilter,
      placeholder: "All",
      filterEditorProps: {
        placeholder: "All",
        dataSource: drawingType,
      },
      render: ({ value }: any) => (value.level ? value.label : value),
    },
    {
      name: "deliverable",
      header: "Deliverables",
      defaultFlex: 1,
      filterEditor: SelectFilter,
      placeholder: "All",
      filterEditorProps: {
        placeholder: "All",
        dataSource: deliverables,
      },
      render: ({ value }: any) => (value.level ? value.label : value),
    },
    {
      name: "volume",
      header: "Volume",
      defaultFlex: 1,
      filterEditor: SelectFilter,
      placeholder: "All",
      filterEditorProps: {
        placeholder: "All",
        dataSource: volume,
      },
      render: ({ value }: any) => (value.level ? value.label : value),
    },
    {
      name: "drawingObjectQR",
      header: "QR",
      defaultFlex: 1,
      render: ({ value }: any) => {
        return (
          <>
            <div className="d-flex flex-column w-100 p-2" key={value.id}>
              <div className="d-flex symbol ">
                <button
                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  onClick={() => {
                    setQrCode(value.url, value.id);
                  }}
                >
                  <KTIcon iconName="eye" className="fs-1" />
                </button>
                {/* ------ Download QR code --------- */}
                <a
                  className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                  // @ts-ignore
                  onClick={() => downloadQRCode(value.url, value.id)}
                >
                  <KTIcon iconName="file-down" className="fs-1" />
                </a>

                <div className="d-none">
                  <QRCode
                    value={value?.url}
                    size={350}
                    logoImage={fetchSignedUrl(value?.logo)}
                    logoHeight={64}
                    logoWidth={128}
                    removeQrCodeBehindLogo={true}
                    enableCORS={true}
                    ecLevel="H"
                    id={value.id}
                  />
                </div>
                <div>
                  <Modal
                    // show={showQRModal}
                    show={qrCodeId === value.id}
                    // @ts-ignore
                    onHide={() => setQrCode(null)}
                  >
                    {/*--------Open Popup QR code----------- */}

                    <Modal.Header closeButton>
                      <Modal.Title>
                        {" "}
                        Scan this QR to view the project details.
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                      {qrCodeDataUrl && (
                        <QRCode
                          value={qrCodeDataUrl || value.url}
                          size={350}
                          logoImage={fetchSignedUrl(value.logo)}
                          logoHeight={64}
                          logoWidth={128}
                          removeQrCodeBehindLogo={true}
                          enableCORS={true}
                          ecLevel="H"
                          id="myQRCode"
                        />
                      )}
                    </Modal.Body>
                  </Modal>
                </div>

                <a
                  href={value.id ? value.url : `https://www.google.com`}
                  className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                  target="_blank"
                  onClick={handleCopyLink}
                  title={showCopied ? "Link copied to clipboard!" : ""}
                >
                  <KTIcon iconName="bookmark" className="fs-3" />
                </a>
                <a
                  href={value.url}
                  className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                  target="_blank"
                >
                  <KTIcon iconName="notepad-bookmark" className="fs-3" />
                </a>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "drawingObjectFiles",
      header: "Revisions",
      defaultFlex: 1,
      render: ({ value }: any) => {
        const fileNameParts = value.file.split(".");
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        const isPdf = fileExtension === "pdf";
        return (
          <>
            <div className="d-flex flex-column w-100 me-2" key={value.id}>
              <div>
                <div className="d-flex align-items-center">
                  <div>
                    <span className="me-2">
                      R{value?.version?.version_number}
                    </span>
                  </div>

                  <button
                    className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                    onClick={() => {
                      setShowVersionHistoryModal(true);
                      getVersionHistory(value.id);
                      setVersionHistoryId(value.id);
                    }}
                  >
                    <KTIcon iconName="eye" className="fs-1" />
                  </button>
                </div>

                <Modal
                  show={versionHistoryId === value.id}
                  onHide={() => setVersionHistoryId(null)}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <div>Drawing Version History</div>
                      <div>
                        <a
                          href={`/project/${value.id}/drawing_version/edit?projectId=${projectId}`}
                          className="btn btn-sm btn-light-primary me-10"
                        >
                          <KTIcon iconName="plus" className="fs-3" />
                          Add
                        </a>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pt-0 pb-0">
                    <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
                      <table className="table table-row-dashed table-row-gray-300 ">
                        <thead>
                          <tr className="fw-bolder fs-6 text-gray-800">
                            <th className="text-center">Rev</th>
                            <th className="text-center">Date</th>
                            <th className="text-center">Description</th>
                            <th className="text-center">File</th>
                            <th className="text-center">QR</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Step 3: Pass the versionHistory data to display in the modal */}
                          {versionHistory
                            .sort(
                              (a: any, b: any) =>
                                b.version_number - a.version_number
                            )
                            .map((version: any, index: number) => {
                              return (
                                <>
                                  <tr key={version.id}>
                                    <td className="text-center align-middle">
                                      R{version.version_number}
                                    </td>
                                    <td className="text-center align-middle">
                                      {/* version.created_at */}
                                      {`${moment(version.created_at).format(
                                        "DD-MM-YYYY"
                                      )}`}
                                    </td>
                                    <td className="text-center align-middle">
                                      {version.description
                                        ? version.description
                                        : "--"}
                                    </td>
                                    <td className="text-center align-middle">
                                      {version.file !== null
                                        ? processFilename(version.file)
                                        : "--"}
                                    </td>
                                    <td className="text-center align-middle">
                                      {version.url !== null &&
                                      version.config !== null ? (
                                        <>
                                          <button
                                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                            onClick={() => {
                                              setQrCode(
                                                version.url,
                                                version.id
                                              );
                                            }}
                                          >
                                            <KTIcon
                                              iconName="eye"
                                              className="fs-1"
                                            />
                                          </button>
                                          <a
                                            className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                                            // @ts-ignore
                                            onClick={() => {
                                              downloadQRCode(
                                                version.url,
                                                version.id
                                              );
                                            }}
                                          >
                                            <KTIcon
                                              iconName="file-down"
                                              className="fs-1"
                                            />
                                          </a>
                                          {index === 0 ? (
                                            <a
                                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                              // href={`/project/${value.id}/update_drawing_version/edit?projectId=${projectId}`}
                                              onClick={handleEditClick}
                                            >
                                              <KTIcon
                                                iconName="pencil"
                                                className="fs-3"
                                              />
                                            </a>
                                          ) : null}
                                          {isPdf && (
                                            <a
                                              className="btn btn-icon btn-bg-light btn-sm me-1 btn-active-color-primary"
                                              onClick={() => {
                                                setTemplateQrCodeDataId(
                                                  version.id
                                                );
                                                setTemplateQrCodeDataUrl(
                                                  version.url
                                                );
                                                handleOpenQRModal(
                                                  version.id,
                                                  version.url
                                                );
                                              }}
                                            >
                                              {loads[version.id] ? (
                                                <div
                                                  className={style.btn_loader}
                                                ></div>
                                              ) : (
                                                <KTIcon
                                                  iconName="folder-down"
                                                  className="fs-3"
                                                />
                                              )}
                                            </a>
                                          )}

                                          {showEditPopup && (
                                            <UpdateDrawingPopup
                                              projectId={projectId}
                                              drawingId={value.id}
                                              onClose={handleCloseEditPopup}
                                            />
                                          )}
                                          <div className="d-none">
                                            <QRCode
                                              value={version.url}
                                              size={350}
                                              logoImage={fetchSignedUrl(
                                                value?.logo
                                              )}
                                              logoHeight={64}
                                              logoWidth={128}
                                              removeQrCodeBehindLogo={true}
                                              enableCORS={true}
                                              ecLevel="H"
                                              id={version.id}
                                            />
                                          </div>
                                          <div>
                                            <Modal
                                              // show={showQRModal}
                                              show={qrCodeId === version.id}
                                              // @ts-ignore
                                              onHide={() => setQrCode(null)}
                                            >
                                              {/*--------Open Popup QR code----------- */}

                                              <Modal.Header closeButton>
                                                <Modal.Title>
                                                  {" "}
                                                  Scan this QR to view the
                                                  project details.
                                                </Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body className="text-center">
                                                {qrCodeDataUrl && (
                                                  <QRCode
                                                    value={
                                                      qrCodeDataUrl ||
                                                      version.url
                                                    }
                                                    size={350}
                                                    logoImage={fetchSignedUrl(
                                                      value.logo
                                                    )}
                                                    logoHeight={64}
                                                    logoWidth={128}
                                                    removeQrCodeBehindLogo={
                                                      true
                                                    }
                                                    enableCORS={true}
                                                    ecLevel="H"
                                                    id="myQRCode"
                                                  />
                                                )}
                                              </Modal.Body>
                                            </Modal>
                                          </div>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "drawingObjectActions",
      header: "Actions",
      defaultFlex: 1,
      render: ({ value }: any) => {
        const drawingId = value.id;
        return (
          <>
            <div key={value.id} className="d-flex">
              {/* <div onClick={() => setSingleDrawing(value.id)}> */}
              <SetDrawingPin drawing={value} />
              {/* </div> */}

              <a
                href={`/project/${drawingId}/drawing/edit?projectId=${projectId}`}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              >
                <KTIcon iconName="pencil" className="fs-3" />
              </a>

              <button
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                onClick={() => setDeleteDrawingId(drawingId)}
              >
                <KTIcon iconName="trash" className="fs-3" />
              </button>
              <Modal
                show={deleteDrawingId === drawingId}
                onHide={() => setDeleteDrawingId(null)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Drawing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this drawing?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setDeleteDrawingId(null)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      handleDelete(drawingId);
                      setDeleteDrawingId(null);
                    }}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {loading ? (
        <div className={style.loader_spinner2}></div>
      ) : (
        <div className="card">
          <div className="d-flex justify-content-between align-items-center h-55px">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">Drawings</span>
              </h3>
            </div>
            <div
              className="card-toolbar"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
              title="Click to add a drawing"
            >
              {selectedData?.length > 0 &&
                (bullkBtnLoader ? (
                  <button className="btn btn-sm btn-light-primary me-10">
                    <div className="d-flex gap-2">
                      <p className={style.btn_loader}></p>
                      Please wait...
                    </div>
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-light-primary me-10"
                    onClick={() => handleDownload()}
                  >
                    <KTIcon iconName="folder-down" className="fs-1" /> Bulk
                    Download
                  </button>
                ))}
              <a
                href={`/project/${projectId}/details/add`}
                className="btn btn-sm btn-light-primary me-10"
              >
                <KTIcon iconName="plus" className="fs-3" />
                New Drawing
              </a>
            </div>
          </div>
          <div>
            <ReactDataGrid
              handle={setGridRef}
              idProperty="id"
              checkboxColumn
              selected={selected}
              onSelectionChange={onSelectionChange}
              defaultFilterValue={filterValue}
              // @ts-ignore
              columns={columns}
              style={{ minHeight: 600 }}
              dataSource={pageProduct}
              pagination={true}
              filterTypes={filterTypes}
              // @ts-ignore
              keyProperty="id"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetailViewDrawing;
